/**
 * A custom React hook that manages all the state and logic required for handling 
 * chat room interactions, including messages, multiple choice questions, 
 * rating scales, AI interactions, and room management.
 * @module useChat
 */

import { useState, useRef, useEffect } from 'react';
import { useSocketEvents, socket } from './socketEvents';
import { useAuth0 } from '@auth0/auth0-react';
import {
  handleSendMessage,
  handleMessageChange,
  handleToggleRegularMessages,
  handleShowClick,
  handleShowResult,
} from './messageHandlers';
import {
  handleMcQuestionChange,
  handleMcOptionChange,
  handleAddOption,
  handleRemoveOption,
  handleMultipleChoice,
  handleEndQuestion,
  handleMcQuestion,
  handleSendMcResponse,
  handleOptionClick,
  broadcastMcQuestionResults,
  initializeMcResponses,
  handleMcItemEndedState,
} from './multipleChoiceHandlers';
import {
  handleEndRatingQuestion,
  broadcastRatingQuestionResults,
} from './ratingScaleHandlers';
import {
  handlePromptChange,
  handleSubmitPrompt,
  handleAnalyzeClick,
  handleChatGPT,
  fetchBestPrompts,
  closeMessageChatGPT,
  summarizeMeeting
} from './chatGPTHandlers';
import {
  handleJoin,
  handleCreateRoom,
  joinRoom,
  createRoom,
  processFile,
} from './roomHandlers';

/**
 * useChat is a custom hook that provides the state and logic for managing a chat room,
 * including handling messages, multiple-choice questions, rating scales, AI interactions, and room management.
 * 
 * @returns {Object} An object containing state variables and functions for managing the chat.
 */
const useChat = () => {
  // State variables
  const [room, setRoom] = useState('');
  const [userType, setUserType] = useState('');
  const [password, setPassword] = useState('');
  const [roomCode, setRoomCode] = useState('');
  const [roomNameError, setRoomNameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [users, setUsers] = useState({});
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [displayedMessages, setDisplayedMessages] = useState([]);
  const [showRegularMessages, setShowRegularMessages] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [chatGPTMessages, setChatGPTMessages] = useState('');
  const [guideQuestions, setGuideQuestions] = useState([]);
  const [prompt, setPrompt] = useState('');
  const [mcQuestion, setMcQuestion] = useState('');
  const [mcOptions, setMcOptions] = useState(['', '']);
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [showMultipleChoice, setShowMultipleChoice] = useState(false);
  const [showConfirmExit, setShowConfirmExit] = useState(false);
  const [showUserInvite, setShowUserInvite] = useState(false);
  const [mcResponses, setMcResponses] = useState(new Map());
  const [totalResponses, setTotalResponses] = useState(0);
  const [messageType, setMessageType] = useState(null);
  const [ratingQuestion, setRatingQuestion] = useState('');
  const [showButtonClicked, setShowButtonClicked] = useState(Array(messages.length).fill(false));
  const [errorMessage, setErrorMessage] = useState('');
  const [questionsFile, setQuestionsFile] = useState(null);
  const [preMcQuestions, setPreMcQuestions] = useState([]);
  const [preFreeQuestions, setPreFreeQuestions] = useState([]);
  const [preRatingQuestions, setPreRatingQuestions] = useState([]);
  const [showPreMcQuestions, setShowPreMcQuestions] = useState(true);
  const [showPreFreeQuestions, setShowPreFreeQuestions] = useState(true);
  const [showPreRatingQuestions, setShowPreRatingQuestions] = useState(true);
  const [messageError, setMessageError] = useState(null);
  const [analyzeMessageId, setAnalyzeMessageId] = useState(null);
  const [disconnected, setDisconected] = useState(false);
  const [chatGPTPrompts, setChatGPTPrompts] = useState(false);

  const { user, isAuthenticated } = useAuth0();
  const rightPanelRef = useRef(null);

  /**
   * Wrapper function for broadcasting multiple-choice question results.
   * 
   * @param {Array} options - The options for the multiple-choice question.
   * @param {string} id - The ID of the multiple-choice question.
   */
  const broadcastMcQuestionResultsWrapper = (options, id) => {
    broadcastMcQuestionResults(options, id, messages);
  };

  // Hook for handling socket events
  useSocketEvents({
    room,
    userType,
    roomCode,
    setUsers,
    setMessages,
    setMcQuestion,
    setMcOptions,
    initializeMcResponses: (options, setMessages, id) => initializeMcResponses(options, setMessages, id),
    setTotalResponses,
    handleOptionClick: (id, index, count) => handleOptionClick(id, index, setMessages, count),
    setShowRegularMessages,
    setRoom,
    setUserType,
    setMcResponses,
    handleMcItemEndedState: (id) => handleMcItemEndedState(id, setMessages),
    setErrorMessage,
    createRoom: (room, selectedFile, roomCode, jsonPromptsFile) => createRoom(room, setRoom, setUserType, selectedFile, processFileWrapper, roomCode, setRoomCode, setChatGPTPrompts, jsonPromptsFile),
    joinRoom: (room, userType, selectedFile, roomCode, jsonPromptsFile) => joinRoom(room, userType, setRoom, setUserType, selectedFile, processFileWrapper, roomCode, setRoomCode, jsonPromptsFile, setChatGPTPrompts),
    setRoomNameError,
    setPasswordError,
    broadcastMcQuestionResultsWrapper,
    setMessageType,
    setMessageError,
    setDisconected,
    setShowConfirmExit,
    setChatGPTPrompts,
  });

  // Effect to update displayed messages when messages state changes
  useEffect(() => {
    setDisplayedMessages(messages);
  }, [messages]);

  /**
   * Handles ending the meeting, resetting the chat state.
   */
  const handleEndMeeting = () => {
    socket.emit('end-meeting');
    setRoom('');
    setUserType('');
    setPassword('');
    setUsers({});
    setMessages([]);
    setDisplayedMessages([]);
    setShowRegularMessages(false);
    setSelectedMessages([]);
    setChatGPTMessages('');
    setPrompt('');
    setMcQuestion('');
    setMcOptions(['', '']);
    setShowPromptModal(false);
    setShowMultipleChoice(false);
    setShowConfirmExit(false);
    setShowUserInvite(false);
    setMcResponses(new Map());
    setTotalResponses(0);
    setMessageType(null);
    setErrorMessage('');
  };

  /**
   * Wrapper function to handle sending messages.
   * @function
   * @param {Event} e - The event object from the message form submission.
   * @param {Object} scale - The scale object for rating questions.
   */
  const handleSendMessageWrapper = (e, scale) => {
    handleSendMessage(e, message, userType, messageType, setMessage, scale);
  };

  /**
   * Wrapper function to handle multiple-choice question submission.
   * @function
   * @param {string} mcQuestion - The multiple-choice question.
   * @param {Array<string>} mcOptions - The options for the multiple-choice question.
   */
  const handleMultipleChoiceWrapper = (mcQuestion, mcOptions) => {
    handleMultipleChoice(
      setShowMultipleChoice,
      handleMcQuestion,
      mcQuestion,
      mcOptions
    );
  };

  /**
   * Wrapper function to process a file for loading pre-set questions.
   * @function
   * @param {File} selectedFile - The file to process.
   */
  const processFileWrapper = (selectedFile) => {
    console.log('processFile called from useChat');
    processFile(selectedFile, setPreMcQuestions, setPreFreeQuestions, setPreRatingQuestions);
  };

  return {
    room, setRoom,
    userType, setUserType,
    password, setPassword,
    roomCode, setRoomCode,
    roomNameError, setRoomNameError,
    passwordError, setPasswordError,
    users, setUsers,
    message, setMessage,
    messages, setMessages,
    displayedMessages, setDisplayedMessages,
    showRegularMessages, setShowRegularMessages,
    selectedMessages, setSelectedMessages,
    chatGPTMessages, setChatGPTMessages,
    guideQuestions, setGuideQuestions,
    prompt, setPrompt,
    mcQuestion, setMcQuestion,
    mcOptions, setMcOptions,
    showPromptModal, setShowPromptModal,
    showMultipleChoice, setShowMultipleChoice,
    showConfirmExit, setShowConfirmExit,
    showUserInvite, setShowUserInvite,
    mcResponses, setMcResponses,
    totalResponses, setTotalResponses,
    messageType, setMessageType,
    ratingQuestion, setRatingQuestion,
    errorMessage, setErrorMessage,
    questionsFile, setQuestionsFile,
    preMcQuestions, setPreMcQuestions,
    preFreeQuestions, setPreFreeQuestions,
    preRatingQuestions, setPreRatingQuestions,
    showPreMcQuestions, setShowPreMcQuestions,
    showPreFreeQuestions, setShowPreFreeQuestions,
    showPreRatingQuestions, setShowPreRatingQuestions,
    messageError, setMessageError,
    user, isAuthenticated,
    rightPanelRef,
    analyzeMessageId, setAnalyzeMessageId,
    disconnected, setDisconected,
    chatGPTPrompts, setChatGPTPrompts,
    handleSendMessage: handleSendMessageWrapper,
    handleJoin: (e) => handleJoin(e, setRoomNameError, setPasswordError),
    handleCreateRoom: (e, currentSubType) => handleCreateRoom(e, currentSubType, setRoomNameError, setPasswordError, user.sub),
    createRoom: (room, fileContent, roomCode, chatGPTPrompts) => createRoom(room, setRoom, setUserType, fileContent, processFileWrapper, roomCode, setRoomCode, setChatGPTPrompts, chatGPTPrompts),
    processFile: processFileWrapper,
    joinRoom: (room, userType, selectedFile, roomCode, jsonPromptsFile) => joinRoom(room, userType, setRoom, setUserType, selectedFile, processFileWrapper, roomCode, setRoomCode, jsonPromptsFile, setChatGPTPrompts),
    handleMessage: (e) => handleMessageChange(e, setMessage),
    handleToggleRegularMessages: () => handleToggleRegularMessages(socket, room),
    handleShowClick: (index) => handleShowClick(index, displayedMessages, setShowButtonClicked),
    handlePromptChange: (e) => handlePromptChange(e, setPrompt),
    handleSubmitPrompt: (setShowPromptModal, handleChatGPT, prompt, userType, messageToAnalyze) => handleSubmitPrompt(setShowPromptModal, handleChatGPT, prompt, userType, chatGPTMessages, setMessages, messageToAnalyze, showRegularMessages),
    handleAnalyzeClick: (msg) => handleAnalyzeClick(msg, setChatGPTMessages, setShowPromptModal, setAnalyzeMessageId),
    handleChatGPT: async (prompt, userType, chatGPTMessages, setMessages, messsageToAnalyze, showRegularMessages) => handleChatGPT(prompt, userType, chatGPTMessages, setMessages, messsageToAnalyze, showRegularMessages, chatGPTPrompts),
    fetchBestPrompts: async () => fetchBestPrompts(chatGPTMessages, setGuideQuestions, chatGPTPrompts),
    closeMessageChatGPT,
    summarizeMeeting,
    handleMcQuestionChange: (e) => handleMcQuestionChange(e, setMcQuestion),
    handleMcOptionChange: (index, value) => handleMcOptionChange(index, value, mcOptions, setMcOptions),
    handleAddOption: () => handleAddOption(mcOptions, setMcOptions),
    handleRemoveOption: (index) => handleRemoveOption(index, mcOptions, setMcOptions),
    handleMultipleChoice: handleMultipleChoiceWrapper,
    handleEndQuestion: (question, options, id) => handleEndQuestion(question, options, id),
    handleSendMcResponse: (id, index) => handleSendMcResponse(id, index, userType),
    handleRatingQuestionChange: (e) => setRatingQuestion(e.target.value),
    handleEndRatingQuestion: (id) => {
      handleEndRatingQuestion(broadcastRatingQuestionResults, messages, id);
    },
    handleEndMeeting,
    handleMcItemEndedState,
    handleShowResult,
  };
};

export default useChat;
