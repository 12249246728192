import React, { useState, useEffect, useContext } from 'react';
import { ChatContext } from '../context/ChatContext';
import { socket } from '../hooks/socketEvents';

/**
 * Represents the countdown timer in the chat application.
 * Synchronizes the timer across users in the same chat room 
 * and allows moderators to start or stop the timer.
 * 
 * @class Timer
 * @classdesc Timer component handles the countdown timer functionality,
 * integrating with the socket to ensure synchronization across users.
 * 
 * @returns {JSX.Element} The rendered timer component.
 */
const Timer = () => {
  const { userType } = useContext(ChatContext);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [timer, setTimer] = useState(null);
  const [isRunning, setIsRunning] = useState(false);

  /**
   * Sets up socket listeners for starting and stopping the timer,
   * and cleans them up on component unmount.
   * 
   * @method
   */
  useEffect(() => {
    socket.on('timer-start', (WsMinutes, WsSeconds) => {
        setMinutes(WsMinutes);
        setSeconds(WsSeconds);
        handleStart();
    });

    socket.on('timer-stop', () => {
      handleStop();
    });

    return () => {
      socket.off('timer-start');
      socket.off('timer-stop');
    };
  }, []);

  /**
   * Handles the timer countdown logic and updates state every second.
   * Stops the timer when it reaches zero.
   * 
   * @method
   */
  useEffect(() => {
    if (isRunning) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 0 && minutes === 0) {
            handleStop();
            return 0;
          } else if (prevSeconds === 0 && minutes > 0) {
            setMinutes((prevMinutes) => prevMinutes - 1);
            return 59;
          } else {
            return prevSeconds - 1;
          }
        });
      }, 1000);
  
      setTimer(interval);
    } else {
      clearInterval(timer);
    }
  
    return () => clearInterval(timer);
  }, [isRunning, minutes, seconds]);
  
  /**
   * Starts the timer.
   * 
   * @method
   */
  const handleStart = () => {
    if (!isRunning) {
      setIsRunning(true);
    }
  };
  
  /**
   * Stops the timer and resets the time to zero.
   * 
   * @method
   */
  const handleStop = () => {
    clearInterval(timer);
    setTimer(null);
    setIsRunning(false);
    setMinutes(0);
    setSeconds(0);
  };

  useEffect(() => {
    return () => clearInterval(timer);
  }, [timer]);

  /**
   * Handles the click event to start or stop the timer.
   * 
   * @method
   * @param {boolean} start - Whether to start or stop the timer.
   */
  const handleClick = (start) => {
    if (start) {
        socket.emit('timer-start', minutes, seconds);
    } else {
        socket.emit('timer-stop'); 
    }
  };

  /**
   * Handles changes to the minutes input field.
   * 
   * @method
   * @param {Event} e - The input change event.
   */
  const handleMinutesChange = (e) => {
    if (!isRunning) {
      const value = e.target.value;
      if (/^\d*$/.test(value)) {
        const numericValue = Number(value);
        if (numericValue <= 59) {
          setMinutes(numericValue);
        }
      }
    }
  };
  
  /**
   * Handles changes to the seconds input field.
   * 
   * @method
   * @param {Event} e - The input change event.
   */
  const handleSecondsChange = (e) => {
    if (!isRunning) {
      const value = e.target.value;
      if (/^\d*$/.test(value)) {
        const numericValue = Number(value);
        if (numericValue <= 59) {
          setSeconds(numericValue);
        }
      }
    }
  };
  
  /**
   * Formats the time value to always display two digits.
   * 
   * @method
   * @param {number} value - The time value to format.
   * @returns {string} The formatted time value.
   */
  const formatTime = (value) => {
    return value.toString().padStart(2, '0');
  };

  return (
    <>
      {userType === 'moderator' ? (
        <div className='timer-inputs'>
          <div className='timer-input-container form-control'>
            <input
              className='timer-input'
              type="number"
              inputMode="numeric"
              value={formatTime(minutes)}
              onChange={handleMinutesChange}
              min="0"
              max="59"
              disabled={isRunning}
              placeholder="00"
            />
            <span>:</span>
            <input
              className='timer-input'
              type="number"
              inputMode="numeric"
              value={formatTime(seconds)}
              onChange={handleSecondsChange}
              min="0"
              max="59"
              disabled={isRunning}
              placeholder="00"
            />
          </div>
          {!isRunning ? (
              <button className='btn btn-primary' onClick={() => handleClick(true)}>Start</button>
          ) : (
              <button className='btn btn-danger' onClick={() => handleClick(false)}>Stop</button>
          )}
        </div>
      ) : (
        <div className='timer-inputs'>
          <div className='timer-input-container form-control'>
            <div className='timer-input'>
                {formatTime(minutes)}
            </div>
            <span>:</span>
            <div className='timer-input'>
                {formatTime(seconds)}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Timer;
