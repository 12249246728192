/**
 * Handles all operations related to multiple choice questions, 
 * including creating, modifying, and broadcasting the results.
 * @module multipleChoiceHandlers
 */


import { socket } from './socketEvents';

/**
 * Handles changes to the multiple choice question.
 * @function
 * @param {Event} e - The input change event.
 * @param {function} setMcQuestion - Function to set the multiple choice question.
 */
export const handleMcQuestionChange = (e, setMcQuestion) => {
  setMcQuestion(e.target.value);
};

/**
 * Handles changes to a specific multiple choice option.
 * @function
 * @param {number} index - The index of the option being changed.
 * @param {string} value - The new value for the option.
 * @param {Array} mcOptions - The current list of options.
 * @param {function} setMcOptions - Function to update the list of options.
 */
export const handleMcOptionChange = (index, value, mcOptions, setMcOptions) => {
  const newOptions = [...mcOptions];
  newOptions[index] = value;
  setMcOptions(newOptions);
};

/**
 * Adds a new option to the list of multiple choice options.
 * @function
 * @param {Array} mcOptions - The current list of options.
 * @param {function} setMcOptions - Function to update the list of options.
 */
export const handleAddOption = (mcOptions, setMcOptions) => {
  if (mcOptions.length < 5) {
    setMcOptions([...mcOptions, '']);
  }
};

/**
 * Removes an option from the list of multiple choice options.
 * @function
 * @param {number} index - The index of the option to remove.
 * @param {Array} mcOptions - The current list of options.
 * @param {function} setMcOptions - Function to update the list of options.
 */
export const handleRemoveOption = (index, mcOptions, setMcOptions) => {
  if (mcOptions.length > 2) {
    setMcOptions(mcOptions.filter((_, i) => i !== index));
  }
};

/**
 * Handles submission of the multiple choice question.
 * @function
 * @param {function} setShowMultipleChoice - Function to hide the multiple choice UI.
 * @param {function} handleMcQuestion - Function to handle the multiple choice question.
 * @param {string} mcQuestion - The multiple choice question.
 * @param {Array} mcOptions - The list of multiple choice options.
 */
export const handleMultipleChoice = (
  setShowMultipleChoice, handleMcQuestion, mcQuestion, mcOptions
) => {
  if (!mcQuestion.trim() || mcOptions.every(option => !option.trim())) {
    return; // Do not send if question or options are empty
  }

  handleMcQuestion(mcQuestion, mcOptions);
  setShowMultipleChoice(false);
};

/**
 * Ends the multiple choice question and requests the selections from the other clients.
 * @function
 * @param {string} question - The multiple choice question.
 * @param {Array} options - The list of multiple choice options.
 * @param {string} id - The ID of the multiple choice question.
 */
export const handleEndQuestion = (question, options, id) => {
  socket.emit('requestSelections', id, socket.id, question, options);
};

/**
 * Sends the multiple choice question and options to the server.
 * @function
 * @param {string} question - The multiple choice question.
 * @param {Array} mcOptions - The list of multiple choice options.
 */
export const handleMcQuestion = (question, mcOptions) => {
  if (question.trim() === '') {
    return;
  }
  const timestamp = new Date().toISOString();
  const payload = { question, mcOptions, timestamp };
  socket.emit('mcQuestion', payload);
};

/**
 * Initializes the map of responses for each multiple choice option.
 * @function
 * @param {Array} options - The list of multiple choice options.
 * @param {function} setMessages - Function to set the messages.
 * @param {string} id - The ID of the multiple choice question.
 */
export const initializeMcResponses = (options, setMessages, id) => {
  setMessages((prevMessages) =>
    prevMessages.map((msg) =>
      msg.id === id
        ? {
            ...msg,
            mcResponses: new Map(
              options.map((option, index) => [index, { option, clicks: 0 }])
            ),
          }
        : msg
    )
  );
};

/**
 * Handles the selection of a multiple choice option.
 * @function
 * @param {string} id - The ID of the multiple choice question.
 * @param {number} index - The index of the selected option.
 * @param {string} userType - The type of user (participant/moderator).
 */
export const handleSendMcResponse = (id, index, userType) => {
  socket.emit('mcResponse', id, index, userType);
};

/**
 * Handles the click event for a multiple choice option.
 * @function
 * @param {string} id - The ID of the multiple choice question.
 * @param {number} index - The index of the clicked option.
 * @param {function} setMessages - Function to set the messages.
 * @param {number} count - The number of responses for the clicked option.
 */
export const handleOptionClick = (id, index, setMessages, count) => {
  setMessages((prevMessages) =>
    prevMessages.map((msg) => {
      if (msg.id === id) {
        const mcResponses = msg.mcResponses || new Map();
        const newMap = new Map(mcResponses);
        if (newMap.has(index)) {
          const optionData = newMap.get(index);
          optionData.clicks += count;
          newMap.set(index, optionData);
        } else {
          console.error('Option not found in the map');
        }
        return { ...msg, mcResponses: newMap };
      }
      return msg;
    })
  );
};

/**
 * Broadcasts the results of the multiple choice question.
 * @function
 * @param {Array} mcOptions - The list of multiple choice options.
 * @param {string} id - The ID of the multiple choice question.
 * @param {Array} messages - The list of messages.
 */
export const broadcastMcQuestionResults = (mcOptions, id, messages) => {
  const msg = messages.find((msg) => msg.id === id);

  if (!mcOptions || !msg.mcResponses) {
    console.error('mcOptions or mcResponses are undefined');
    return;
  }

  const resultsArray = mcOptions.map((option, index) => {
    const response = msg.mcResponses.get(index);
    const responses = response ? response.clicks : 0;
    const percentage =
      msg.totalResponses > 0 ? (responses / msg.totalResponses) * 100 : 0;
    return percentage.toFixed(2);
  });
  socket.emit('McResult', resultsArray, msg.id);
  socket.emit('endMc', msg.id);
};

/**
 * Updates the state of multiple choice questions that have ended.
 * @function
 * @param {string} id - The ID of the multiple choice question.
 * @param {function} setMessages - Function to set the messages.
 */
export const handleMcItemEndedState = (id, setMessages) => {
  setMessages((prevMessages) =>
    prevMessages.map((msg) => {
      if (msg.messageType === 0 && msg.id === id) {
        return { ...msg, ended: true };
      }
      return msg;
    })
  );
};
