import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

/**
 * Represents the login button in the application.
 * Triggers the Auth0 login flow when clicked.
 * 
 * @class LoginButton
 * @classdesc LoginButton component initiates the authentication process
 * through Auth0 when the user clicks the button.
 * 
 * @returns {JSX.Element} The rendered button that initiates the Auth0 login process.
 */
const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  
  return (
    <button className="btn btn-lg btn-secondary btn-block" onClick={() => loginWithRedirect()}>
      Log In
    </button>
  );
};

export default LoginButton;
