import React, { useContext } from 'react';
import UserList from './UserList';
import { ChatContext } from '../context/ChatContext';
import Logo from '../img/Logo.svg';
import ChatroomIcon from '../img/24x24-Chatroom-Icon-White.svg';
import HiddenMessageIcon from '../img/24x24-Hidden-Message-Icon.svg';
import VisibleMessageIcon from '../img/24x24-Visible-Message-Icon.svg';
import '../styles/style.css';

/**
 * Represents the sidebar in the chat application.
 * Displays chat room information, the user list, and control buttons 
 * for moderators and participants.
 * 
 * @class Sidebar
 * @classdesc Sidebar component provides an interface for users to view room details,
 * manage participants, and control message visibility.
 * 
 * @param {Object} props - The component props.
 * @param {boolean} props.isSidebarOpen - Determines if the sidebar is open. Only applicable for mobile layout.
 * @returns {JSX.Element} The rendered sidebar component.
 */
const Sidebar = ({ isSidebarOpen }) => {
  const {
    room, userType, roomCode, users, showRegularMessages,
    handleToggleRegularMessages, setShowConfirmExit, setShowUserInvite
  } = useContext(ChatContext);

  /**
   * Returns the current date in '[Month] [Day], [Year]' format.
   * 
   * @method
   * @returns {string} The current date as a string.
   */
  const getCurrentDate = () => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date().toLocaleDateString('en-US', options);
  };

  /**
   * Capitalizes the first letter of a given string.
   * 
   * @method
   * @param {string} string - The string to capitalize.
   * @returns {string} The string with the first letter capitalized.
   */
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className={`sidebar fixed ${isSidebarOpen ? 'slide-right' : ''}`}>
      <div className="sidebar-inner">
        <div className="sidebar-logo">
          <img src={Logo} alt="Sidebar Logo" />
        </div>

        <div className="card panel sidebar-panel chatroom-name text-center">
          <div className="panel-icon mb-3">
            <img src={ChatroomIcon} alt="Chatroom icon" />
          </div>
          <h3>{room}</h3>
          <h3>Room Code: {roomCode}</h3>
          <p className="mb-1">User Type: <strong>{capitalizeFirstLetter(userType)}</strong></p>
          <span className="created-on">Created on: <strong>{getCurrentDate()}</strong></span>
        </div>

        <div className="card panel sidebar-panel participants">
          <UserList users={users} />
        </div>
        {userType === 'moderator' && (
          <div className="d-grid sidebar-btn-group">
            <button className="btn btn-secondary invite" onClick={() => setShowUserInvite(true)}>Invite New Users</button>
            <button className={`btn btn-secondary ${showRegularMessages ? 'show' : 'hide'}`} onClick={handleToggleRegularMessages}>
              {showRegularMessages ? "Hide future messages" : "Show future messages"}
            </button>
            <button className="btn btn-danger" onClick={() => setShowConfirmExit(true)}>End Meeting</button>
          </div>
        )} 

        {userType === 'participant' && (
          <div className="card panel sidebar-panel reg-messages-status text-center" style={{ padding: '0px', paddingTop: '5px' }}>
            <p className="mb-1">Regular Messages</p>
            <h3 className="mb-3">{showRegularMessages ? "Shown" : "Hidden"}</h3>
            <div className="panel-icon mb-4">
              <img src={showRegularMessages ? VisibleMessageIcon : HiddenMessageIcon} alt="Chatroom icon" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
