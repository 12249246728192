/**
 * Handles all operations related to ChatGPT interactions, including handling prompts,
 * analyzing messages, and generating summaries for the meeting.
 * @module chatGPTHandlers
 */

import axios from 'axios';
import { socket } from './socketEvents';

/**
 * Handles changes to the AI prompt input field.
 * @function
 * @param {Event} e - The event object from the input field.
 * @param {Function} setPrompt - Function to update the state of the prompt.
 */
export const handlePromptChange = (e, setPrompt) => {
    setPrompt(e.target.value);
};

/**
 * Disables the Modal and passes the message and prompt information to handleChatGPT
 * @function
 * @param {Function} setShowPromptModal - Function to control the visibility of the prompt modal.
 * @param {Function} handleChatGPT - Function to handle the AI interaction.
 * @param {string} prompt - The prompt text to submit.
 * @param {string} userType - The type of user submitting the prompt.
 * @param {string} chatGPTMessages - The messages to be analyzed by the AI.
 * @param {Function} setMessages - Function to update the state of the messages.
 * @param {Object} messageToAnalyze - The message object to analyze.
 * @param {boolean} showRegularMessages - Flag indicating whether to show regular messages.
 */
export const handleSubmitPrompt = (
    setShowPromptModal,
    handleChatGPT,
    prompt,
    userType,
    chatGPTMessages,
    setMessages,
    messageToAnalyze,
    showRegularMessages
) => {
    setShowPromptModal(false);
    handleChatGPT(prompt, userType, chatGPTMessages, setMessages, messageToAnalyze, showRegularMessages);
};

/**
 * Handles the click event to show the prompt modal and pass it the messages.
 * @function
 * @param {Object} msg - The message object to analyze.
 * @param {Function} setChatGPTMessages - Function to update the state of chatGPTMessages.
 * @param {Function} setShowPromptModal - Function to control the visibility of the prompt modal.
 * @param {Function} setAnalyzeMessageId - Function to store the ID of the message to analyze.
 */
export const handleAnalyzeClick = (msg, setChatGPTMessages, setShowPromptModal, setAnalyzeMessageId) => {
    if (msg.messageType === 1) {
        let updatedMessage = `***Question***\n${msg.message}\n**Responses**\n`;
        if (msg.responses) {
            msg.responses.forEach((response, index) => {
                if (!response.isAI) {
                    updatedMessage += `Response ${index + 1}: ${response.text}\n`;
                }
            });
        } else {
            updatedMessage += "No responses.\n";
        }
        setChatGPTMessages(updatedMessage);
        setAnalyzeMessageId(msg.id);
    }

    setShowPromptModal(true);
};

/**
 * Submits the response to the free response question and updates the message state.
 * @function
 * @param {string} id - The ID of the message being analyzed.
 * @param {Array} newResponses - The new responses.
 * @param {Function} setMessages - Function to update the state of the messages.
 */
const handleSubmitResponse = (id, newResponses, setMessages) => {
    setMessages((prevMessages) => {
        const newMessages = prevMessages.map((msg) => {
            if (msg.id === id) {
                return { ...msg, responses: newResponses };
            }
            return msg;
        });

        const updatedMessage = newMessages.find((msg) => msg.id === id);
        if (updatedMessage) {
            socket.emit('update-response', { id, newResponses: updatedMessage.responses });
        }

        return newMessages;
    });

    socket.emit('new-response', { id, newResponses });
};

/**
 * Handles the AI interaction to analyze the selected messages based on the provided prompt.
 * @function
 * @param {string} promptText - The text of the AI prompt.
 * @param {string} userType - The type of user initiating the interaction. Should always be moderator.
 * @param {string} chatGPTMessages - The messages to be analyzed by the AI.
 * @param {Function} setMessages - Function to update the array of messages.
 * @param {Object} messageToAnalyze - The message object to analyze.
 * @param {boolean} showRegularMessages - Flag indicating whether to show regular messages.
 * @param {Object} chatGPTPrompts - The prompts configuration for AI interactions.
 */
export const handleChatGPT = async (
    promptText,
    userType,
    chatGPTMessages,
    setMessages,
    messageToAnalyze,
    showRegularMessages,
    chatGPTPrompts
) => {
    if (userType !== "moderator") {
        console.log("User is not a moderator, skipping analysis");
        return;
    }

    const combinedContent = `***UserPrompt***\n${promptText}\n${chatGPTMessages}`;
    const promptTextWithContext = chatGPTPrompts.defaultAnalyzePrompt;

    const messagesToAnalyze = [
        {
            role: "system",
            content: promptTextWithContext,
        },
        {
            role: "assistant",
            content: combinedContent,
        },
    ];

    try {
        const response = await axios.post(`${process.env.REACT_APP_SOCKET_URL}/api/analyze`, {
            model: "gpt-4o-2024-08-06",
            messages: messagesToAnalyze,
            response_format: {
                type: "json_schema",
                json_schema: {
                    name: "analysis_response",
                    strict: true,
                    schema: {
                        type: "object",
                        properties: {
                            analysisType: { type: "string" },
                            followUpQuestions: {
                                type: "array",
                                items: { type: "string" }
                            },
                            findings: {
                                type: "array",
                                items: {
                                    type: "object",
                                    properties: {
                                        heading: { type: "string" },
                                        items: {
                                            type: "array",
                                            items: {
                                                type: "object",
                                                properties: {
                                                    title: { type: "string" },
                                                    description: { type: "string" }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        required: ["analysisType", "followUpQuestions", "findings"],
                        additionalProperties: false
                    }
                }
            },
            max_tokens: 2000,
            temperature: 0.7,
        }, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        let aiResponse = response.data.choices[0].message.content;

        if (aiResponse.startsWith("```json")) {
            aiResponse = aiResponse.slice(7);
            aiResponse = aiResponse.slice(0, -3);
        }

        aiResponse = JSON.parse(aiResponse.trim());

        const newResponses = [...messageToAnalyze.responses, { text: aiResponse, show: showRegularMessages, isAI: true }];
        handleSubmitResponse(messageToAnalyze.id, newResponses, setMessages);
    } catch (error) {
        console.error("Error calling the proxy API:", error);
    }
};

/**
 * Fetches the best AI prompts for the current context based on the provided messages.
 * @function
 * @param {string} chatGPTMessages - The messages to be analyzed by the AI.
 * @param {Function} setGuideQuestions - Function to set the four selected guide questions.
 * @param {Object} chatGPTPrompts - The prompts configuration for AI interactions.
 */
export const fetchBestPrompts = async (chatGPTMessages, setGuideQuestions, chatGPTPrompts) => {
    const promptTextWithContext = chatGPTPrompts.fetchPromptsPrompt;

    const guideQuestions = [
        { label: "Summarize Key Points", prompt: "Summarize the key points from the selected messages." },
        { label: "Give an Example", prompt: "Give an example of what is described in the selected messages." },
        { label: "Pros and Cons", prompt: "What are some potential pros and cons of what is described in the selected messages?" },
        { label: "Follow-up Questions", prompt: "Based on the given responses, what are 3 potential follow-up questions?" },
        { label: "Identify Preferences", prompt: "Identify the preferences or opinions expressed in the selected messages." },
        { label: "Analyze Sentiment", prompt: "Analyze the sentiment of the selected messages to determine the overall attitude or tone." },
        { label: "Suggest a Solution", prompt: "Based on the given responses, suggest an optimal solution or compromise." },
        { label: "Compare Responses", prompt: "Compare and contrast the selected messages to highlight similarities and differences." },
        { label: "Identify Common Themes", prompt: "Identify any common themes or recurring ideas in the selected messages." },
        { label: "Highlight Key Differences", prompt: "What are the key differences between the opinions or points made in the selected messages?" }
    ];

    const guideQuestionsString = JSON.stringify(guideQuestions);
    const combinedContent = chatGPTMessages + guideQuestionsString;

    const messagesToAnalyze = [
        {
            role: "system",
            content: promptTextWithContext,
        },
        {
            role: "user",
            content: combinedContent,
        },
    ];

    try {
        const response = await axios.post(`${process.env.REACT_APP_SOCKET_URL}/api/analyze`, {
            model: "gpt-4o",
            messages: messagesToAnalyze,
            response_format: { "type": "json_object" },
            max_tokens: 2000,
            temperature: 0.7,
        }, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        let chatResponse = response.data.choices[0].message.content;

        if (chatResponse.startsWith("```json")) {
            chatResponse = chatResponse.slice(7);
            chatResponse = chatResponse.slice(0, -3);
        }

        chatResponse = chatResponse.trim();

        const selectedPrompts = JSON.parse(chatResponse);
        setGuideQuestions(selectedPrompts);
    } catch (error) {
        console.error("Error calling the OpenAI API:", error);
    }
};

/**
 * Handles the AI interaction to analyze the free response message item on close.
 * @function
 * @param {string} question - The question being analyzed.
 * @param {Array} responses - The responses to the question.
 * @param {Object} chatGPTPrompts - The prompts configuration for AI interactions.
 * @returns {Object} The AI-generated analysis response object.
 */
export const closeMessageChatGPT = async (question, responses, chatGPTPrompts) => {
    const formattedResponses = responses.map((res, index) => `Response ${index + 1}: ${res.text}`).join('\n');
    const promptText = `Question: ${question}\nAnalyze the following responses:\n${formattedResponses}`;

    const messagesToAnalyze = [
        {
            role: "system",
            content: chatGPTPrompts.closeMessagePrompt,
        },
        {
            role: "user",
            content: promptText,
        },
    ];

    try {
        const response = await axios.post(`${process.env.REACT_APP_SOCKET_URL}/api/analyze`, {
            model: "gpt-4o-2024-08-06",
            messages: messagesToAnalyze,
            response_format: {
                type: "json_schema",
                json_schema: {
                    name: "analysis_response",
                    strict: true,
                    schema: {
                        type: "object",
                        properties: {
                            analysisType: { type: "string" },
                            followUpQuestions: {
                                type: "array",
                                items: { type: "string" }
                            },
                            findings: {
                                type: "array",
                                items: {
                                    type: "object",
                                    properties: {
                                        heading: { type: "string" },
                                        items: {
                                            type: "array",
                                            items: {
                                                type: "object",
                                                properties: {
                                                    title: { type: "string" },
                                                    description: { type: "string" }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        required: ["analysisType", "followUpQuestions", "findings"],
                        additionalProperties: false
                    }
                }
            },
            max_tokens: 2000,
            temperature: 0.7,
        }, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        let aiResponse = response.data.choices[0].message.content;

        if (aiResponse.startsWith("```json")) {
            aiResponse = aiResponse.slice(7);
            aiResponse = aiResponse.slice(0, -3);
        }

        aiResponse = JSON.parse(aiResponse.trim());

        return {
            text: aiResponse,
            isAI: true
        };
    } catch (error) {
        console.error("Error calling the proxy API:", error);
        return {
            text: "Error analyzing the responses.",
            isAI: true
        };
    }
};

/**
 * Summarizes the entire meeting using AI based on the displayed messages. Ignores all previous AI messages.
 * @function
 * @param {Array} displayedMessages - The messages from the chat room to be summarized.
 * @param {Object} chatGPTPrompts - The prompts configuration for AI interactions.
 * @returns {string} The AI-generated summary of the meeting.
 */
export const summarizeMeeting = async (displayedMessages, chatGPTPrompts) => {
    const promptTextWithContext = chatGPTPrompts.summarizeMeetingPrompt;
    let meetingText = "Here are all the messages from the chat room:\n";
    for (const msg of displayedMessages) {
        switch (msg.messageType) {
            case 0:
                meetingText += `Multiple Choice Question: ${msg.message}\n`;
                msg.mcResponses.forEach((response) => {
                    const clicks = response.clicks || 0;
                    const percentage = msg.totalResponses > 0 ? (clicks / msg.totalResponses) * 100 : 0;
                    meetingText += `${response.option}: ${percentage.toFixed(2)}% (${clicks})\n`;
                });
                break;
            case 1:
                meetingText += `Free Response Question: ${msg.message}\n`;
                if (msg.responses && Array.isArray(msg.responses)) {
                    const responsesText = msg.responses
                        .filter(response => !response.isAI)
                        .map(response => `Response: ${response.text}`)
                        .join('\n');
                    meetingText += `All Responses:\n${responsesText}\n`;
                }
                break;
            case 2:
                meetingText += `Slider Question: ${msg.message}\n`;

                const responses = msg.responses || [];
                const responseCount = responses.length;

                if (responseCount > 0) {
                    const averageRating = (responses.reduce((sum, response) => sum + response, 0) / responseCount).toFixed(2);

                    const responseCounts = responses.reduce((acc, response) => {
                        acc[response] = (acc[response] || 0) + 1;
                        return acc;
                    }, {});

                    const responseCountsString = Object.entries(responseCounts)
                        .map(([value, count]) => `${count} user(s) answered: ${value}`)
                        .join(', ');

                    meetingText += `Average: ${averageRating} (${responseCount} responses)\n${responseCountsString}\n`;
                } else {
                    meetingText += 'No responses\n';
                }
                break;
            default:
                meetingText += `Message: ${msg.message}\n`;
                break;
        }
    }

    const messagesToAnalyze = [
        {
            role: "system",
            content: promptTextWithContext,
        },
        {
            role: "user",
            content: meetingText,
        },
    ];

    try {
        const response = await axios.post(`${process.env.REACT_APP_SOCKET_URL}/api/analyze`, {
            model: "gpt-4o",
            messages: messagesToAnalyze,
            max_tokens: 2000,
            temperature: 0.7,
        }, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        const chatGPTResponse = response.data.choices[0].message.content;

        return chatGPTResponse;
    } catch (error) {
        console.error("Error calling the proxy API:", error);
        return "Error analyzing the responses.";
    }
};
