import React, { useContext, useState, useEffect } from 'react';
import { ChatContext } from '../context/ChatContext';
import QRCode from 'qrcode.react';
import { Modal } from 'bootstrap';

import chatroomIcon from "../img/24x24-Chatroom-Icon-White.svg";

/**
 * Represents the invite users modal in the chat application.
 * Handles the process of inviting users to a meeting room,
 * including generating and copying the invitation link and displaying a QR code.
 * 
 * @class InviteUsersModal
 * @classdesc InviteUsersModal component manages the display and functionality
 * of the invitation process for meeting rooms.
 * 
 * @returns {JSX.Element|null} The rendered invite users modal component, or null if not shown.
 */
const InviteUsersModal = () => {
  const {
    roomCode, setShowUserInvite, showUserInvite
  } = useContext(ChatContext);

  const [linkCopied, setLinkCopied] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);

  /**
   * Initializes and displays the modal when `showUserInvite` is true.
   * Cleans up the modal and removes it from the DOM when hidden.
   * 
   * @method
   */
  useEffect(() => {
    let myModal = null;
    if (showUserInvite) {
      const modalElement = document.getElementById('ModalInviteUsers');
      myModal = new Modal(modalElement);
      myModal.show();

      modalElement.addEventListener('hidden.bs.modal', () => {
        setShowUserInvite(false);
        document.body.classList.remove('modal-open');
        document.querySelector('.modal-backdrop')?.remove();
      });
    }
    return () => {
      if (myModal) {
        myModal.hide();
      }
    };
  }, [showUserInvite, setShowUserInvite]);

  if (!showUserInvite) return null;

  const baseUrl = process.env.REACT_APP_SOCKET_URL || 'http://localhost:3001';
  const inviteLink = `${baseUrl}?roomCode=${encodeURIComponent(roomCode)}`;

  /**
   * Copies the invitation link to the clipboard and displays a confirmation message.
   * 
   * @method
   */
  const handleCopyLink = () => {
    navigator.clipboard.writeText(inviteLink).then(() => {
      setLinkCopied(true);
      setTimeout(() => setLinkCopied(false), 2000); // Reset after 2 seconds
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  /**
   * Hides the invite modal and resets the link copied state.
   * 
   * @method
   */
  const handleBack = () => {
    setShowUserInvite(false);
    setLinkCopied(false);
  };

  return (
    <>
      <div className="modal fade" tabIndex="-1" id="ModalInviteUsers">
        <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
          <div className="modal-content">
            <div className="modal-body">
              <div className="modal-inner">
                <div className="panel-icon mb-4">
                  <img src={chatroomIcon} alt="Modal icon" />
                </div>
                <span className="back-arrow" onClick={handleBack}></span>
                <h3 className="text-center mb-4">Invite Users</h3>
                <label className="form-label">Meeting URL</label>
                <div className="copy-url">
                  <span
                    className={`copy-url-icon ${linkCopied ? 'checkmark-icon disabled' : ''}`}
                    onClick={handleCopyLink}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="Copy URL"
                  ></span>
                  <input
                    type="text"
                    className="form-control"
                    value={inviteLink}
                    readOnly
                  />
                </div>
                <div className="form-text">Copy and share this URL with users to provide instant access to the meeting chatroom</div>

                <div className="or-divider">OR</div>

                <div className="d-flex justify-content-center">
                  <button className="btn btn-primary" onClick={() => setShowQRCode(true)}>Generate QR Code</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showQRCode && (
          <div className="qrCodeOverlay" onClick={() => setShowQRCode(false)}>
            <QRCode value={inviteLink} size={256} />
          </div>
        )}
      </div>
    </>
  );
};

export default InviteUsersModal;
