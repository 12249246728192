/**
 * Handles operations related to rating scale questions, including managing the display,
 * submitting responses, and broadcasting results within the application.
 * @module ratingScaleHandlers
 */
import { socket } from './socketEvents';

/**
 * Ends the rating question and broadcasts the results.
 * @function
 * @param {function} broadcastRatingQuestionResults - Function to broadcast the rating question results.
 * @param {Array} messages - List of messages.
 * @param {string} id - ID of the rating question to be ended.
 */
export const handleEndRatingQuestion = (broadcastRatingQuestionResults, messages, id) => {
  const message = messages.find(msg => msg.id === id);

  if (!message) {
    console.error(`No message found with id: ${id}`);
    return;
  }
  const ratingQuestion = message.message || 'No question provided';

  broadcastRatingQuestionResults(ratingQuestion, id);
  socket.emit('endRating', id);
};

/**
 * Broadcasts the rating question results.
 * @function
 * @param {string} ratingQuestion - The rating question.
 * @param {string} id - The ID of the rating question.
 */
export const broadcastRatingQuestionResults = (ratingQuestion, id) => {
  let resultsMessage = `${ratingQuestion}`;
  socket.emit('result', resultsMessage, id, "moderator");
};
