import React, { useContext, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; 
import './styles/style.css';
import axios from 'axios';
import { Tooltip } from 'bootstrap';

import Logo from './img/Logo.svg';
import ChatroomIcon from './img/38x36-Chatroom-Icon-White.svg';

import Sidebar from './components/Sidebar';
import RightPanel from './components/RightPanel';
import PromptModal from './components/PromptModal';
import ExitModal from './components/ExitModal';
import InviteUsersModal from './components/InviteUsersModal';
import { ChatProvider, ChatContext } from './context/ChatContext';
import LoginButton from './components/LoginButton';
import LogoutButton from './components/LogoutButton';

/**
 * The main component for the chat application.
 * Manages the views, user authentication, and room states.
 * 
 * @class ChatApp
 * @classdesc ChatApp component handles the overall layout and navigation within the chat application.
 * It manages user authentication, room creation, and joining functionalities.
 * 
 * @returns {JSX.Element} The rendered ChatApp component.
 */
const ChatApp = () => {
  const { handleJoin, handleCreateRoom, room, userType, setErrorMessage, roomNameError, 
    setRoomNameError, passwordError, setPasswordError, user, isAuthenticated
  } = useContext(ChatContext);
  
  const [view, setView] = useState('initial');
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [roomName, setRoomName] = useState('');
  const [roomCodeLocal, setRoomCodeLocal] = useState('');
  const [userTypeState, setUserTypeState] = useState('participant');
  const [URLJoin, setURLJoin] = useState(false);
  const [subType, setSubType] = useState('free');
  const [subTypeTooltip, setSubTypeTooltip] = useState('');
  const [remainingMeetings, setRemainingMeetings] = useState(0);

  /**
   * Effect to handle URL parameters on initial load.
   */
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const roomCode = urlParams.get('roomCode');
    if (roomCode) {
      setRoomCodeLocal(roomCode);
      setUserTypeState(userType || 'participant');
      setPassword(userType === 'moderator' ? password || '' : '');
      setShowPassword(userType === 'moderator');
      setView('join');
      setURLJoin(true);
    }
  }, []);

  /**
   * Effect to automatically join the room if URLJoin is true.
   */
  useEffect(() => {
    if (URLJoin) {
      const e = { preventDefault: () => {} };
      handleJoin(e);
    }
  }, [URLJoin]);

  /**
   * Effect to set the view to 'chat' if room and userType are set.
   */
  useEffect(() => {
    if (room && userType) {
      setView('chat');
    }
  }, [room, userType]);

  /**
   * Effect to update the document title based on the room name.
   */
  useEffect(() => {
    document.title = room ? `${room} - TimelyInsights.ai` : 'TimelyInsights.ai';
  }, [room]);

  /**
   * Effect to initialize Bootstrap tooltips.
   */
  useEffect(() => {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));
  }, []);

  /**
   * Handles changes in the user type (participant/moderator).
   * @param {Event} e - The event triggered by changing the user type.
   */
  const handleUserTypeChange = (e) => {
    if (e.target.value === 'moderator') {
      setShowPassword(true);
    } else {
      setShowPassword(false);
      setPassword('');
    }
    setUserTypeState(e.target.value);
  };

  /**
   * Handles the back button click to reset the view and form state.
   */
  const handleBack = () => {
    setView('initial');
    setShowPassword(false);
    setUserTypeState('participant');
    setPassword('');
    setErrorMessage('');
    setRoomNameError('');
    setPasswordError('');
  };

  /**
   * Handles key press events for form submission.
   * @param {Event} e - The event triggered by key press.
   */
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (view === 'join') {
        handleJoin(e);
      } else if (view === 'create') {
        handleCreateRoom(e, subType);
      }
    }
  };

  /**
   * Fetches user metadata from the backend and updates the subscription type and remaining meetings.
   */
  useEffect(() => {
    const fetchUserMetadata = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SOCKET_URL}/api/user-metadata`, {
          params: { userId: user.sub }
        });
  
        const metadata = response.data.metadata;
        setSubType(metadata.subType);
        setRemainingMeetings(metadata.remainingMeetings);
  
      } catch (error) {
        console.error('Error fetching user metadata:', error);
      }
    };
  
    if (isAuthenticated && user) {
      fetchUserMetadata();
    }
  }, [isAuthenticated, user]);

  /**
   * Capitalizes the first letter of a string.
   * @param {string} string - The string to capitalize.
   * @returns {string} - The string with the first letter capitalized.
   */
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  /**
   * Updates the subscription type tooltip based on the selected subscription type.
   */
  useEffect(() => {
    switch(subType) {
      default:
        setSubTypeTooltip("Free:\n" +
          " - Total # of meetings: 1\n" +
          " - Participants: 5\n" +
          " - Moderators: 1\n" +
          " - # of Questions: 3\n" +
          "\nClick for more info"
        );
        break;
      case "standard":
        setSubTypeTooltip("Standard:\n" +
          " - Meetings per month: 4\n" +
          " - Participants: 25\n" +
          " - Moderators: 2\n" +
          " - # of Questions: 10\n" +
          "\nClick for more info"
        );
        break;
        case "unlimited":
          setSubTypeTooltip("Unlimited:\n" +
            " - Meetings per month: unlimited\n" +
            " - Participants: unlimited\n" +
            " - Moderators: unlimited\n" +
            " - # of Questions: unlimited\n" +
            "\nAdmin Account"
          );
          break;
    }
  }, [subType]);

  return (
    <div>
      {view === 'initial' ? (
        <>
          <body className="landing-body">
            <div className="landing-wrap">
              <div className="landing-container mx-auto">
                <div className="text-center my-4">
                  <img className="landing-logo" src={Logo} alt="Logo" />
                </div>
                <div className="card panel position-relative">
                  {isAuthenticated && <div className="logout-button-container"><LogoutButton /></div>}
                  <div className="panel-icon mb-5">
                    <img src={ChatroomIcon} alt="Chatroom icon" />
                  </div>
                  <div className="d-grid">
                    <button className="btn btn-lg btn-primary btn-block mb-3" onClick={() => setView('join')}>Join room</button>
                    {isAuthenticated ? (
                      <button className="btn btn-lg btn-secondary btn-block" onClick={() => setView('create')}>Create room</button>
                    ) : (
                      <LoginButton />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <footer className="d-flex align-items-center justify-content-center">
              &copy; 2024 TimelyInsights. All rights reserved.
            </footer>
          </body>
        </>
      ) : view === 'join' ? (
        <body className="landing-body">
          <div className="landing-wrap">
            <div className="landing-container mx-auto">
              <div className="text-center my-4">
                <img className="landing-logo" src={Logo} alt="Logo"/>
              </div>
              <div className="card panel">
                <h3 className="text-center mb-5">Join Room</h3>
                <form onSubmit={(e) => handleJoin(e)} onKeyDown={handleKeyPress}>
                  <div className="mb-3">
                    <label className="form-label">Room code</label>
                    <input type="text" id="room" className={`form-control ${roomNameError ? 'is-invalid' : ''}`} placeholder="Enter room code" value={roomCodeLocal} onChange={(e) => setRoomCodeLocal(e.target.value)} />
                    {roomNameError && <div className="invalid-feedback">{roomNameError}</div>}
                  </div>
                  <div className="mb3">
                    <label className="form-label">User type</label>
                    <select id="userType" className="form-select" aria-label="User type" value={userTypeState} onChange={handleUserTypeChange}>
                      <option value="participant">Participant</option>
                      <option value="moderator">Moderator</option>
                    </select>
                  </div>
                  {showPassword && (
                    <div>
                      <div className="mb-3">
                          <label className="form-label">Moderator password</label>
                          <input type="password" id="moderatorPassword" className={`form-control ${passwordError ? 'is-invalid' : ''}`} placeholder="Enter moderator password" value={password} onChange={(e) => setPassword(e.target.value)} />
                          {passwordError && <div className="invalid-feedback">{passwordError}</div>}
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Upload meeting questions</label>
                        <span className="input-file">
                          <input type="file" id="file" accept=".csv,.json" name="meeting-questions"/>
                        </span>
                        <div className="form-text">
                          .json or .csv file only
                          <div>
                            <a href="/H7jsonExample.json" download="H7jsonExample.json" target="_blank" rel="noopener noreferrer">Download JSON Example</a>
                            {' | '}
                            <a href="/H7csvExample.csv" download="H7csvExample.csv" target="_blank" rel="noopener noreferrer">Download CSV Example</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row mt-5">
                    <div className="col-sm-6 d-grid">
                      <button className="btn btn-secondary my-2" onClick={handleBack}>
                        <span className="back-arrow"></span>
                        Back
                      </button>
                    </div>
                    <div className="col-sm-6 d-grid">
                      <button className="btn btn-primary my-2">Join room</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <footer className="d-flex align-items-center justify-content-center">
              &copy; 2024 TimelyInsights. All rights reserved.
          </footer>
        </body>
      ) : view === 'create' ? (
        <body className="landing-body">
          <div className="landing-wrap">
            <div className="landing-container mx-auto">
              <div className="text-center my-4">
                <img className="landing-logo" src={Logo} alt="Logo" />
              </div>
              <div className="card panel">
                <h3 className="text-center mb-2">Create Room</h3>
                <div className="text-center sub-type">
                  Subscription Type: {capitalizeFirstLetter(subType)}
                  <span
                    className="tooltip-icon-circle"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title= {subTypeTooltip}
                    onClick={() => window.open('https://timelyinsights.ai/#subscription', '_blank')}
                  >
                    ?
                  </span>
                </div>
                <div className="text-center sub-type mb-3">
                  Meetings Remaining: {remainingMeetings}
                </div>
                <form onSubmit={(e) => handleCreateRoom(e, subType)} onKeyDown={handleKeyPress}>
                  <div className="mb-3">
                    <label className="form-label">Room name</label>
                    <input type="text" id="room" className={`form-control ${roomNameError ? 'is-invalid' : ''}`} placeholder="Enter room name" value={roomName} onChange={(e) => setRoomName(e.target.value)} />
                    {roomNameError && <div className="invalid-feedback">{roomNameError}</div>}
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Moderator password</label>
                    <input type="text" id="moderatorPassword" className={`form-control ${passwordError ? 'is-invalid' : ''}`} placeholder="Enter moderator password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    {passwordError && <div className="invalid-feedback">{passwordError}</div>}
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Upload meeting questions</label>
                    <span className="input-file">
                      <input type="file" id="file" accept=".csv,.json" name="meeting-questions"/>
                    </span>
                      <div className="form-text">
                        .json or .csv file only
                        <div>
                          <a href="/H7jsonExample.json" download="H7jsonExample.json" target="_blank" rel="noopener noreferrer">Download JSON Example</a>
                          {' | '}
                          <a href="/H7csvExample.csv" download="H7csvExample.csv" target="_blank" rel="noopener noreferrer">Download CSV Example</a>
                        </div>
                      </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-sm-6 d-grid">
                      <button className="btn btn-secondary my-2" onClick={handleBack}>
                        <span className="back-arrow"></span>
                        Back
                      </button>
                    </div>
                    <div className="col-sm-6 d-grid">
                      <button className="btn btn-primary my-2">Create room</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <footer className="d-flex align-items-center justify-content-center">
              &copy; 2024 TimelyInsights. All rights reserved.
          </footer>
        </body>
      ) : (
        <>
          <div className={`wrapper ${userType === 'moderator' ? 'moderator-wrapper' : ''}`}>
            <Sidebar />
            <RightPanel />
            <PromptModal />
            <ExitModal />
            <InviteUsersModal />
          </div>
          <div className="overlay" id="overlay"></div>
        </>
      )}
    </div>
  );
};

/**
 * The main App component that wraps the ChatApp with the ChatProvider.
 * Provides global state management for the chat application.
 * 
 * @returns {JSX.Element} The rendered App component.
 */
const App = () => (
  <ChatProvider>
    <ChatApp />
  </ChatProvider>
);

export default App;
