import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/style.css';
import { Auth0Provider } from '@auth0/auth0-react';

const domain = "dev-vmgcdjn3deuo52bg.us.auth0.com";
const clientId = "Fl2MpOnihWyQZdxzOjnD0FGnw1tDuXo0";

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
