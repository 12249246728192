import React, { useContext, useRef, useEffect, useState } from 'react';
import MessageItem from './MessageItem';
import MessageForm from './MessageForm';
import McMessageForm from './McMessageForm';
import { ChatContext } from '../context/ChatContext';
import { socket } from '../hooks/socketEvents';
import HeaderLogo from '../img/Header-Logo.svg';
import NoMessageIcon from '../img/96x96-No-Message-Icon.svg';
import '../styles/style.css';
import Sidebar from './Sidebar';
import Timer from './Timer';

/**
 * Represents the right panel in the chat application.
 * Handles the display of messages, forms for sending messages,
 * and integrates with the chat context and socket events.
 * 
 * @class RightPanel
 * @classdesc RightPanel component manages the main chat area.
 */
const RightPanel = () => {
  const {
    displayedMessages, message, handleSendMessage, handleMessage,
    handleShowClick, messageType, setMessages, messages
  } = useContext(ChatContext);

  const rightPanelRef = useRef(null);
  const mainContentRef = useRef(null);
  const chatBodyRef = useRef(null);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  /**
   * Sets up and cleans up socket event listeners for updating messages and responses.
   * 
   */
  useEffect(() => {
    socket.on('response-updated', ({ id, newResponses }) => {
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === id ? { ...msg, responses: newResponses } : msg
        )
      );
      adjustMainContentMargin();
    });

    socket.on('messages-updated', (newMessages) => {
      setMessages(newMessages);
      adjustMainContentMargin();
    });

    return () => {
      socket.off('response-updated');
      socket.off('messages-updated');
    };
  }, [setMessages]);

  /**
   * Adjusts the main content margin based on the size of the chat body.
   * 
   */
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1025) {
        setIsSidebarOpen(false);
      }
      adjustMainContentMargin();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    adjustMainContentMargin();
  }, [messageType, messages]);

  /**
   * Adjusts the margin at the bottom of the main content based on the height of the chat body.
   * @method
   */
  const adjustMainContentMargin = () => {
    if (mainContentRef.current && chatBodyRef.current) {
      const chatBodyHeight = chatBodyRef.current.offsetHeight;
      mainContentRef.current.style.marginBottom = `${chatBodyHeight + 200}px`;
    }
  };

  /**
   * Handles the submission of responses and updates the socket with new responses.
   * @method
   * @param {string} id - The ID of the message being responded to.
   * @param {Array} newResponses - The new responses to be updated.
   */
  const handleSubmitResponse = (id, newResponses) => {
    setMessages((prevMessages) => {
      const newMessages = prevMessages.map((msg) =>
        msg.id === id ? { ...msg, responses: newResponses } : msg
      );

      const updatedMessage = newMessages.find((msg) => msg.id === id);
      socket.emit('update-response', { id, newResponses: updatedMessage.responses });

      return newMessages;
    });
  };

  return (
    <div className="main" ref={rightPanelRef}>
      <header className="text-right">
        <button className="btn btn-secondary btn-menu" id="btn-menu-sidebar" onClick={() => setIsSidebarOpen(true)}>Sidebar</button>
        <div className="header-logo">
          <img src={HeaderLogo} alt="Header Logo"/>
        </div>
        <Timer/>
      </header>

      {displayedMessages.length === 0 ? (
        <div className="main-content empty">
          <div className="empty-state text-center">
            <img src={NoMessageIcon} alt="Empty state image"/>
            <h2>No message, yet.</h2>
            <p>Send your first message or invite some users</p>
          </div>
        </div>
      ) : (
        <div className="main-content" id="chat-scroll-onload" ref={mainContentRef}>
          <div className="chat-container">
            {displayedMessages.map((msg, index) => (
              <MessageItem
                key={msg.id}
                index={index}
                msg={msg}
                handleShowClick={() => handleShowClick(index, displayedMessages, setMessages)}
                handleSubmitResponse={(id, newResponses) => handleSubmitResponse(id, newResponses)}
              />
            ))}
          </div>
        </div>
      )}

      {messageType !== 0 ? (
        <MessageForm
          message={message}
          handleSendMessage={handleSendMessage}
          handleMessage={handleMessage}
          chatBodyRef={chatBodyRef} // Pass ref to MessageForm
          adjustMainContentMargin={adjustMainContentMargin} // Pass margin adjustment function to MessageForm
        />
      ) : (
        <McMessageForm 
        adjustMainContentMargin={adjustMainContentMargin}
        />
      )}

      <Sidebar isSidebarOpen={isSidebarOpen}/> {/* Only for mobile layout */}

      {isSidebarOpen && <div id="overlay" className="block-content" onClick={() => setIsSidebarOpen(false)}></div>} {/* Only for mobile layout */}
    </div>
  );
};

export default RightPanel;