import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { ChatContext } from '../context/ChatContext';
import UserResponseItem from './UserResponseItem';
import { socket } from '../hooks/socketEvents';
import { closeMessageChatGPT, handleAnalyzeClick } from '../hooks/chatGPTHandlers';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../styles/style.css';
import { format } from 'date-fns';

/**
 * Represents a free-response item in the chat application.
 * Renders a free-response question allowing users to submit answers 
 * and moderators to manage responses.
 * 
 * @class FreeResponseItem
 * @classdesc FreeResponseItem component handles the display and functionality
 * of free-response questions and their corresponding user responses.
 * 
 * @param {Object} props - The component props.
 * @param {string} props.id - The unique ID of the message.
 * @param {Object} props.msg - The message object containing the question and responses.
 * @param {Function} props.handleSubmitResponse - Function to handle the submission of responses.
 * @returns {JSX.Element} The rendered free-response item component.
 */
const FreeResponseItem = ({ id, msg, handleSubmitResponse }) => {
  const { userType, showRegularMessages, setChatGPTMessages, setShowPromptModal, 
    setAnalyzeMessageId, messages, chatGPTPrompts } = useContext(ChatContext);
  
  const [response, setResponse] = useState('');
  const [responses, setResponses] = useState(msg.responses || []);
  const [showInput, setShowInput] = useState(true);
  const [showCloseButton, setShowCloseButton] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showDotsAlert, setShowDotsAlert] = useState(false);
  const dropdownRef = useRef(null);
  const alertRef = useRef(null);
  const dropdownAlertRef = useRef(null);
  const moreIconRef = useRef(null); // Ref for the dots icon

  /**
   * Sets up listeners for socket events to handle closing the input and receiving new responses.
   * 
   * @method
   */
  useEffect(() => {
    setResponses(msg.responses || []);
    
    socket.on('close-response-input', ({ id: responseId }) => {
      if (responseId === id) {
        setShowInput(false);
        setShowCloseButton(false);
      }
    });

    socket.on('new-response', ({ id: responseId, newResponses }) => {
      if (responseId === id) {
        setResponses(newResponses);
      }
    });

    return () => {
      socket.off('close-response-input');
      socket.off('new-response');
    };
  }, [msg.responses, id]);

  /**
   * Handles window resize events to manage dropdown visibility and alerts.
   * 
   * @method
   */
  const handleResize = useCallback(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1025) {
      setDropdownVisible(false);
      setShowDotsAlert(false);
    }
  }, []);

  /**
   * Handles clicks outside of dropdowns and alerts to hide them.
   * 
   * @method
   * @param {Event} event - The click event.
   */
  const handleClickOutside = (event) => {
    if (alertRef.current && !alertRef.current.contains(event.target)) {
      setShowAlert(false);
    }
    if (dropdownAlertRef.current && !dropdownAlertRef.current.contains(event.target)) {
      setShowDotsAlert(false);
    }
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowAlert(false);
      setShowDotsAlert(false);
      setDropdownVisible(false);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleResize]);

  /**
   * Handles changes in the response input field.
   * 
   * @method
   * @param {Event} e - The input change event.
   */
  const handleInputChange = (e) => {
    setResponse(e.target.value);

    const textarea = document.getElementById(`responseTextarea-${id}`);
    if (textarea.classList.contains('is-invalid')) {
      textarea.classList.remove('is-invalid');
    }
  };

  /**
   * Handles key press events to submit the response when Enter is pressed.
   * 
   * @method
   * @param {Event} e - The key press event.
   */
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleResponseSubmit();
    }
  };

  /**
   * Submits the response and updates the state and server.
   * 
   * @method
   */
  const handleResponseSubmit = () => {
    const textarea = document.getElementById(`responseTextarea-${id}`);

    if (response.trim()) {
      const newResponses = [...responses, { text: response, show: showRegularMessages }];
      setResponses(newResponses);
      handleSubmitResponse(id, newResponses);
      socket.emit('new-response', { id, newResponses });
      setResponse('');
      textarea.classList.remove('is-invalid');
      
      setDropdownVisible(false);
      setShowAlert(false);
      setShowDotsAlert(false);
    } else {
      textarea.classList.add('is-invalid');
    }
  };

  /**
   * Toggles the visibility of individual responses.
   * 
   * @method
   * @param {number} responseIndex - The index of the response to toggle.
   */
  const handleShowResponseClick = (responseIndex) => {
    const updatedResponses = responses.map((res, idx) =>
      idx === responseIndex ? { ...res, show: !res.show } : res
    );
    setResponses(updatedResponses);
    socket.emit('show-response', { id, responseIndex });
  };

  /**
   * Shows all responses for the message.
   * 
   * @method
   */
  const handleShowAllClick = () => {
    setDropdownVisible(false);
    if (responses.length === 0) {
      console.warn('No responses to show.');
      return;
    }

    const updatedResponses = responses.map((res) => ({ ...res, show: true }));
    setResponses(updatedResponses);
    socket.emit('show-response', { id, responseIndex: null });
  };

  /**
   * Closes the input field for the message and processes responses with AI if applicable.
   * 
   * @method
   */
  const handleCloseClick = async () => {
    setDropdownVisible(false);
    setShowInput(false);
    setShowCloseButton(false);
    socket.emit('close-response-input', { id });
    const message = messages.find(msg => msg.id === id);
    if (!message.analyzeClicked && responses.length > 0) {
      const chatResponse = await closeMessageChatGPT(msg.message, responses, chatGPTPrompts);
      const newResponses = [...responses, { text: chatResponse.text, show: showRegularMessages, isAI: true }];
      setResponses(newResponses);
      handleSubmitResponse(id, newResponses);
      socket.emit('new-response', { id, newResponses });
    }
  };

  /**
   * Handles the analyze button click to analyze the responses using AI.
   * 
   * @method
   */
  const handleAnalyzeButtonClick = () => {
    setDropdownVisible(false);
    if (responses.length === 0) {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1025) {
        setShowAlert(true);
      } else {
        setShowDotsAlert(true);
      }
      return;
    }
    handleAnalyzeClick(msg, setChatGPTMessages, setShowPromptModal, setAnalyzeMessageId, handleResponseSubmit);
  };

  /**
   * Toggles the collapsed state of the message.
   * 
   * @method
   */
  const toggleCollapse = () => setCollapsed(!collapsed);

  /**
   * Toggles the visibility of the dropdown menu for message actions.
   * 
   * @method
   */
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  /**
   * Sets the reference to the dropdown element and adds/removes event listeners
   * for detecting clicks outside the dropdown.
   * 
   * @method
   * @param {HTMLElement|null} node - The dropdown element or null.
   */
  const setDropdownRef = (node) => {
    if (node) {
      dropdownRef.current = node;
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  };

  const formattedTime = format(new Date(msg.timestamp), 'p');

  return (
    <div className="chat-item moderator">
      <div className="chat-heading">
        <p className="user-type">
          Moderator <span className="time">{formattedTime}</span>
        </p>
        <p className="message">{msg.message}</p>
        <div className="button-group">
          <button className={`btn btn-sm ${collapsed ? 'btn-up' : 'btn-down'}`} onClick={toggleCollapse}></button>
        </div>
        {userType === 'moderator' && (
          <>
            <div className={`dropdown dropdown-actions ${dropdownVisible ? 'show' : ''}`} ref={setDropdownRef}>
              <div
                className={`more-icon ${dropdownVisible ? 'show' : ''}`}
                onClick={toggleDropdown}
                ref={moreIconRef}  // Set ref for the dots icon
              ></div>
              <ul className={`dropdown-menu ${dropdownVisible ? 'show' : ''}`}>
                <li><a className="dropdown-item" onClick={handleShowAllClick}>Show all</a></li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={handleAnalyzeButtonClick}
                  >
                    Analyze
                  </a>
                  {showAlert && (
                    <div ref={alertRef} className="custom-alert mt-2" role="alert">
                      No Responses
                    </div>
                  )}
                </li>
                {showCloseButton && (
                  <li><a className="dropdown-item" onClick={handleCloseClick}>Close</a></li>
                )}
              </ul>
              {showDotsAlert && (
                <div ref={dropdownAlertRef} className="dropdown-alert" role="alert">
                  No Responses
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {showInput && (
        <div className="chat-body">
          <form>
            <textarea 
              id={`responseTextarea-${id}`}
              className="form-control"
              value={response}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder="Type your answer..."
            />
            <button type="button" className="btn btn-primary btn-sm btn-inline" onClick={handleResponseSubmit}>Send</button>
          </form>
        </div>
      )}
      {!collapsed && (
        <div className="chat-body">
          {responses.map((res, idx) => (
            <UserResponseItem 
              key={idx} 
              response={res.text} 
              onShowClick={() => handleShowResponseClick(idx)} 
              isModerator={userType === 'moderator'} 
              show={res.show}
              isAI={res.isAI}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default FreeResponseItem;
