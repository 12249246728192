import React, { useContext, useState, useEffect } from 'react';
import { ChatContext } from '../context/ChatContext';
import { format } from 'date-fns';
import { socket } from '../hooks/socketEvents';

/**
 * Represents a multiple-choice question item in the chat.
 * Renders the multiple-choice question, allows users to select an option,
 * and displays results if the question has ended.
 * 
 * @class MultipleChoiceItem
 * @classdesc MultipleChoiceItem component manages the display and interaction
 * for multiple-choice questions within the chat.
 * 
 * @param {Object} props - The component props.
 * @param {Object} props.msg - The message object containing the question and options.
 * @param {boolean} props.ended - Whether the question has ended.
 * @param {string} props.id - The unique ID of the message.
 * @param {string} props.timestamp - The timestamp of when the message was sent.
 * @returns {JSX.Element} The rendered multiple-choice item component.
 */
const MultipleChoiceItem = ({ msg, ended, id, timestamp }) => {
  const { userType, mcQuestion, mcOptions, handleSendMcResponse, handleEndQuestion, handleShowResult } = useContext(ChatContext);

  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
  const [question] = useState(mcQuestion);
  const [options] = useState(mcOptions);
  const [liveCount, setLiveCount] = useState(0);

  /**
   * Resets the selected option if the question has ended.
   * 
   * @method
   */
  useEffect(() => {
    if (ended) {
      setSelectedOptionIndex(null);
    }
  }, [ended]);

  /**
   * Handles the selection of an option and sends the response to the server.
   * 
   * @method
   */
  useEffect(() => {
    const handleSelectionResponse = (idToCheck) => {
      if (idToCheck === id) {
        if (selectedOptionIndex !== null) {
          handleSendMcResponse(id, selectedOptionIndex);
        }
      }
      socket.off('selectionsResponse', handleSelectionResponse);
    };

    socket.on('selectionsResponse', handleSelectionResponse);

    return () => {
      socket.off('selectionsResponse', handleSelectionResponse);
    };
  }, [id, selectedOptionIndex]);

  /**
   * Handles the live update of response counts as they come in from the server.
   * 
   * @method
   */
  useEffect(() => {
    const handleMcCounter = (count, msgId) => {
      if (msg.id === msgId) {
        setLiveCount(prevCount => prevCount + count);
      }
    };

    socket.on('mcCounter', handleMcCounter);

    return () => {
      socket.off('mcCounter', handleMcCounter);
    };
  }, []);

  const formattedTime = format(new Date(timestamp), 'p');

  return (
    <div className="chat-item moderator">
      <div className="chat-heading mc-chat-heading">
        <div className="mc-moderator-info">
          <p className="user-type mc-user-type">
            Moderator 
            <span className="time mc-time">{formattedTime}</span>
          </p>
          <p className="mc-message-container">{question}</p>
        </div>
        {userType === 'moderator' && (
          <p className="mc-responses" style={{paddingRight: "0px"}}>Responses: {liveCount}</p>
        )}
      </div>

      <div className="chat-body">
        <form>
          <div className="row">
            {!ended ? (
              <>
                {options.map((option, i) => (
                  <div className="col-lg-6" key={`${id}-${i}`}>
                    <div className="d-grid mb-2">
                      <input
                        className="btn-check"
                        type="radio"
                        name={`options-${id}`}
                        checked={selectedOptionIndex === i}
                        id={`${id}-${i}`}
                        onClick={() => {
                          if (selectedOptionIndex === i) {
                            socket.emit('mcResponse', msg.id, selectedOptionIndex, userType, false);
                            setSelectedOptionIndex(null);
                            socket.emit("mcCounter", -1, msg.id);
                          }
                          else if (selectedOptionIndex !== null){
                            socket.emit('mcResponse', msg.id, selectedOptionIndex, userType, false);
                            setSelectedOptionIndex(i);
                            socket.emit('mcResponse', msg.id, i, userType, true);
                          }
                          else{
                            setSelectedOptionIndex(i);
                            socket.emit('mcResponse', msg.id, i, userType, true);
                            socket.emit("mcCounter", 1, msg.id);
                          }
                        }}
                        autoComplete="off"
                      />
                      <label
                        className={`btn btn-outline-secondary btn-block ${selectedOptionIndex === i ? 'active' : ''}`}
                        htmlFor={`${id}-${i}`}
                      >
                        {option}
                      </label>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                {options.map((option, i) => (
                  <div key={`${id}-${i}`} className="col-lg-6">
                    <div className="answer-item mb-2">
                      <span>{option}</span>
                      <strong className={userType !== 'moderator' && !msg.showRegularMessages ? 'hidden-item' : ''}>
                        {userType === 'moderator' || msg.showRegularMessages ? `${msg.resultsArray[i]}%` : '??%'}
                      </strong>
                      <span className="percentage" style={{ width: userType === 'moderator' || msg.showRegularMessages ? `${msg.resultsArray[i]}%` : '0%' }}></span>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
          {ended && userType === 'moderator' && (
            <button
              className="btn btn-sm btn-secondary"
              onClick={(e) => {
                e.preventDefault();
                handleShowResult(id);
              }}
            >
              {msg.showRegularMessages ? "Hide" : "Show"}
            </button>
          )}
          {userType === 'moderator' && !ended && (
            <button
              className="btn btn-danger"
              onClick={(e) => {
                e.preventDefault();
                handleEndQuestion(question, options, msg.id);
              }}
            >
              End Question
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default MultipleChoiceItem;
