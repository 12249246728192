import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { ChatContext } from '../context/ChatContext';
import MultipleChoiceItem from './MultipleChoiceItem';
import FreeResponseItem from './FreeResponseItem';
import RatingScaleItem from './RatingScaleItem';
import '../styles/style.css';
import { format } from 'date-fns';

/**
 * Represents a single chat message item in the application.
 * The message could be a multiple-choice, free-response, rating scale item, or a regular message.
 * 
 * @class MessageItem
 * @classdesc MessageItem component handles the rendering of a single message in the chat,
 * with various types such as multiple-choice, free-response, and rating scale.
 * 
 * @param {Object} props - The component props.
 * @param {number} props.index - The index of the message in the list.
 * @param {Object} props.msg - The message object containing message details.
 * @param {Function} props.handleShowClick - Function to toggle the visibility of the message.
 * @param {Function} props.handleSubmitResponse - Function to handle the submission of responses.
 * @returns {JSX.Element} The rendered message item component.
 */
const MessageItem = ({ index, msg, handleShowClick, handleSubmitResponse }) => {
  const { userType } = useContext(ChatContext);
  const [randomString, setRandomString] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const moreIconRef = useRef(null);

  /**
   * Toggles the visibility of the dropdown menu for message actions such as show/hide.
   * 
   * @method
   */
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
 
  /**
   * Sets the reference to the dropdown element and adds/removes event listeners
   * for detecting clicks outside the dropdown.
   * 
   * @method
   * @param {HTMLElement|null} node - The dropdown element or null.
   */
  const setDropdownRef = (node) => {
    if (node) {
       dropdownRef.current = node;
       document.addEventListener('mousedown', handleClickOutside);
    } else {
       document.removeEventListener('mousedown', handleClickOutside);
    }
  };

  /**
   * Handles window resize events to hide the dropdown if the window width exceeds a threshold.
   * 
   * @method
   */
  const handleResize = useCallback(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1025) {
      setDropdownVisible(false);
    }
  }, []);

  /**
   * Handles scroll events to hide the dropdown.
   * 
   * @method
   */
  const handleScroll = useCallback(() => {
    setDropdownVisible(false);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleResize, handleScroll]);

  /**
   * Hides the dropdown menu if a click is detected outside of it.
   * 
   * @method
   * @param {Event} event - The click event.
   */
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  /**
   * Renders the content of the message, either the actual message or a hidden placeholder.
   * 
   * @method
   * @returns {JSX.Element} The message content.
   */
  const renderMessageContent = () => {
    const originalMessage = "You're gonna have to be smarter than that";

    if (msg.showRegularMessages || userType === 'moderator' || (msg.userType === 'moderator' && msg.messageType === null)) {
      return <p className="message">{msg.message}</p>;
    } else {
      if (msg.message.length > originalMessage.length) {
        return <p className="message hidden-item">{originalMessage + randomString}</p>;
      } else {
        return <p className="message hidden-item">{originalMessage}</p>;
      }
    }
  };

  /**
   * Generates a random string of a specified length.
   * 
   * @method
   * @param {number} length - The length of the string to be randomized.
   * @returns {string} The generated random string.
   */
  const randomizeString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = ' ';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  useEffect(() => {
    const originalMessage = "You're gonna have to be smarter than that";
    const extraLength = msg.message.length - originalMessage.length;
    setRandomString(randomizeString(extraLength));
  }, [msg]);

  /**
   * Returns the CSS class name based on the user type (moderator/participant).
   * 
   * @method
   * @returns {string} The CSS class name.
   */
  const getUserTypeClass = () => {
    if (msg.userType === 'moderator') return 'moderator';
    if (msg.userType === 'participant') return 'participant';
    return '';
  };

  const formattedTime = format(new Date(msg.timestamp), 'p');

  if (msg.messageType === 0) {
    return (
      <MultipleChoiceItem
        key={index}
        msg={msg}
        ended={msg.ended}
        id={msg.id}
        timestamp={msg.timestamp}
      />
    );
  } else if (msg.messageType === 1) {
    return (
      <FreeResponseItem
        key={index}
        msg={msg}
        handleShowClick={handleShowClick}
        handleSubmitResponse={handleSubmitResponse}
        messageType={msg.messageType}
        id={msg.id}
        timestamp={msg.timestamp}
      />
    );
  } else if (msg.messageType === 2) {
    return (
      <RatingScaleItem
        key={index}
        msg={msg}
        id={msg.id}
        ended={msg.ended}
        scale={msg.scale}
        timestamp={msg.timestamp}
      />
    );
  }

  return (
    <div className={`chat-item ${getUserTypeClass()}`}>
      <div className="chat-heading">
        <p className="user-type">
          {msg.userType.charAt(0).toUpperCase() + msg.userType.slice(1)}
          <span className="time">{formattedTime}</span>
        </p>
        {renderMessageContent()}
        {userType === 'moderator' && (msg.userType === 'participant' || msg.userType === 'System') && (
          <>
            <div className={`dropdown dropdown-actions ${dropdownVisible ? 'show' : ''}`} ref={setDropdownRef}>
              <div
                className={`more-icon ${dropdownVisible ? 'show' : ''}`}
                onClick={toggleDropdown}
                ref={moreIconRef}
              ></div>
              <ul className={`dropdown-menu ${dropdownVisible ? 'show' : ''}`}>
                <button className={`${dropdownVisible ? 'dropdown-item' : 'btn btn-sm btn-secondary'}`} onClick={handleShowClick}>
                  {msg.showRegularMessages ? "Hide" : "Show"}
                </button>
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MessageItem;
