import { useEffect } from 'react';
import io from 'socket.io-client';

// Connect to the socket server
export const socket = io.connect(process.env.REACT_APP_SOCKET_URL);

/**
 * Custom hook to handle socket events. Parameters allow the socket events here to use these variables and functions.
 * 
 * @param {Object} params - The parameters for the socket events.
 * @param {string} params.room - The name of the current room.
 * @param {string} params.userType - The type of user (regular/moderator).
 * @param {string} params.roomCode - The code of the current room.
 * @param {function} params.setUsers - Function to set the list of users.
 * @param {function} params.setMessages - Function to set the list of messages.
 * @param {function} params.setMcQuestion - Function to set the current multiple choice question.
 * @param {function} params.setMcOptions - Function to set the options for the multiple choice question.
 * @param {function} params.initializeMcResponses - Function to initialize multiple choice responses.
 * @param {function} params.setTotalResponses - Function to set the total number of responses.
 * @param {function} params.handleOptionClick - Function to handle the click event on a multiple choice option.
 * @param {function} params.setShowRegularMessages - Function to toggle the visibility of regular messages.
 * @param {function} params.setRoom - Function to set the current room.
 * @param {function} params.setUserType - Function to set the user type.
 * @param {function} params.setMcResponses - Function to set the multiple choice responses.
 * @param {function} params.handleMcItemEndedState - Function to handle the state when a multiple choice item ends.
 * @param {function} params.setErrorMessage - Function to set error messages.
 * @param {function} params.createRoom - Function to create a room.
 * @param {function} params.joinRoom - Function to join a room.
 * @param {function} params.setRoomNameError - Function to set an error message for the room name.
 * @param {function} params.setPasswordError - Function to set an error message for the password.
 * @param {function} params.broadcastMcQuestionResultsWrapper - Wrapper function to broadcast multiple choice question results.
 * @param {function} params.setMessageType - Function to set the message type.
 * @param {function} params.setMessageError - Function to set message errors.
 * @param {function} params.setDisconected - Function to set the disconnected state.
 * @param {function} params.setShowConfirmExit - Function to control the visibility of the confirm exit modal.
 */
export const useSocketEvents = ({
  room,
  userType,
  roomCode,
  setUsers,
  setMessages,
  setMcQuestion,
  setMcOptions,
  initializeMcResponses,
  setTotalResponses,
  handleOptionClick,
  setShowRegularMessages,
  setRoom,
  setUserType,
  setMcResponses,
  handleMcItemEndedState,
  setErrorMessage,
  createRoom,
  joinRoom,
  setRoomNameError,
  setPasswordError,
  broadcastMcQuestionResultsWrapper,
  setMessageType,
  setMessageError,
  setDisconected,
  setShowConfirmExit
}) => {

  // Join the room when room or userType changes
  useEffect(() => {
    if (roomCode && userType) {
      socket.emit('join', { roomCode, userType });
    }
  }, [roomCode, userType]);

  // Handle initial socket events for room verification and creation
  useEffect(() => {
    /**
     * Handles the 'verify' event from the server, which verifies room access.
     * 
     * @param {string} room - The name of the room.
     * @param {boolean} success - Whether the verification was successful.
     * @param {string} message - The message returned by the server.
     * @param {string} userType - The type of user.
     * @param {File} selectedFile - The file selected by the moderator.
     * @param {string} roomCode - The room code.
     * @param {Object} jsonPromptsFile - JSON file containing prompts for AI interactions.
     */
    socket.on('verify', (room, success, message, userType, selectedFile, roomCode, jsonPromptsFile) => {
      if (success) {
        joinRoom(room, userType, selectedFile, roomCode, jsonPromptsFile);
        setErrorMessage(''); // Clear any previous error message
      } else {
        if(message === 'Password incorrect'){
          setPasswordError(message);
        }
        else{
          setRoomNameError(message);
        }
        console.error(message);
      }
    });

    /**
     * Handles the 'roomCreated' event from the server, which create the room on success.
     * 
     * @param {string} room - The name of the room.
     * @param {boolean} success - Whether the room creation was successful.
     * @param {string} message - The message returned by the server.
     * @param {File} selectedFile - The file selected by the moderator.
     * @param {string} roomCode - The room code.
     * @param {Object} jsonPromptsFile - JSON file containing prompts for AI interactions.
     */
    socket.on('roomCreated', (room, success, message, selectedFile, roomCode, jsonPromptsFile) => {
      if (success) {
        console.log(`Creating room ${room}`);
        createRoom(room, selectedFile, roomCode, jsonPromptsFile);
      } else {
        console.error(message);
        setRoomNameError(message);
      }
    });

    return () => {
      socket.off('verify');
      socket.off('roomCreated');
    };
  }, [room, userType]);

  // Handle various socket events during the chat session
  useEffect(() => {
    /**
     * Handles the 'user-joined' event, which is triggered when a new user joins the room.
     * 
     * @param {Object} data - The data containing the updated list of users.
     * @param {Array} data.users - The updated list of users in the room.
     */
    socket.on('user-joined', ({ users }) => {
      console.log('User joined:', users);
      setUsers(users);
    });

    /**
     * Handles the 'user-left' event, which is triggered when a user leaves the room.
     * 
     * @param {Object} data - The data containing the updated list of users.
     * @param {Array} data.updatedUsers - The updated list of users in the room.
     */
    socket.on('user-left', ({ users: updatedUsers }) => {
      console.log('User left:', updatedUsers);
      if (updatedUsers) {
        setUsers(updatedUsers);
      }
    });

    /**
     * Handles the 'message' event, which is triggered when a new message is sent in the chat.
     * 
     * @param {Object} data - The data containing the message details.
     * @param {string} data.userType - The type of user who sent the message.
     * @param {string} data.message - The content of the message.
     * @param {boolean} data.showRegularMessages - Whether to show regular messages.
     * @param {number} data.messageType - The type of message (e.g., text, multiple choice, etc.).
     * @param {string} data.id - The unique ID of the message.
     * @param {Object} data.scale - The scale object used for rating questions, if applicable.
     * @param {string} data.timestamp - The timestamp of the message.
     */
    socket.on('message', ({ userType: senderType, message, showRegularMessages, messageType, id, scale, timestamp }) => {
      setMessages((prevMessages) => {
        if (prevMessages.find((msg) => msg.id === id)) {
          return prevMessages;
        }
        return [...prevMessages, { userType: senderType, message, showRegularMessages, messageType, id, scale, timestamp }];
      });
    });

    /**
     * Handles the 'msgError' event, which is triggered when there is an error sending a message.
     * 
     * @param {string} message - The error message.
     */
    socket.on('msgError', (message) => {
      console.log(`Received msgError: ${message}`);
      setMessageType(null);
      setMessageError(message);
    });

    /**
     * Handles the 'show-msg' event, which toggles the visibility of a specific message.
     * 
     * @param {string} id - The unique ID of the message.
     */
    socket.on('show-msg', (id) => {
      setMessages((prevMessages) => {
        const newMessages = prevMessages.map(msg => {
          if (msg.id === id) {
            return { ...msg, showRegularMessages: !msg.showRegularMessages };
          }
          return msg;
        });
        return newMessages;
      });
    });

    /**
     * Handles the 'mcQuestion' event, which is triggered when a new multiple choice question is sent.
     * 
     * @param {Object} data - The data containing the multiple choice question details.
     * @param {string} data.question - The multiple choice question.
     * @param {Array} data.mcOptions - The list of multiple choice options.
     * @param {string} data.id - The unique ID of the multiple choice question.
     * @param {boolean} data.showRegularMessages - Whether to show regular messages.
     * @param {string} data.timestamp - The timestamp of the multiple choice question.
     */
    socket.on('mcQuestion', ({ question, mcOptions, id, showRegularMessages, timestamp }) => {
      setMcQuestion(question);
      setMcOptions(mcOptions);
      setMessages((prevMessages) => [
        ...prevMessages, 
        {
          userType: "moderator",
          message: question,
          showRegularMessages: showRegularMessages,
          messageType: 0,
          id,
          timestamp,
          mcResponses: new Map(mcOptions.map((option, index) => [index, { option, clicks: 0 }]))
        }
      ]);
    });

    /**
     * Handles the 'endMc' event, which is triggered when a multiple choice question ends.
     * 
     * @param {string} id - The unique ID of the multiple choice question.
     */
    socket.on('endMc', (id) => {
      setMcQuestion('');
      setMcOptions(['', '']);
      setTotalResponses(0);
      handleMcItemEndedState(id);
    });

    /**
     * Handles the 'result' event, which is triggered when the results of a question are shown.
     * 
     * @param {string} message - The results message to display.
     * @param {string} id - The unique ID of the multiple choice question.
     * @param {boolean} showRegularMessages - Whether to show regular messages.
     */
    socket.on('result', (message, id, showRegularMessages) => {
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === id ? { ...msg, message: message, ended: true, showRegularMessages: showRegularMessages } : msg
        )
      );
    });

    /**
     * Handles the 'McResult' event, which is triggered when multiple choice results are calculated.
     * 
     * @param {Array} resultsArray - The array of results for each option.
     * @param {string} id - The unique ID of the multiple choice question.
     * @param {boolean} showRegularMessages - Whether to show regular messages.
     */
    socket.on('McResult', (resultsArray, id, showRegularMessages) => {
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === id ? { ...msg, ended: true, showRegularMessages: showRegularMessages, resultsArray } : msg
        )
      );
    });

    /**
     * Handles the 'showResult' event, which toggles the visibility of the result for a specific message.
     * 
     * @param {string} id - The unique ID of the message.
     */
    socket.on('showResult', (id) => {
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === id ? { ...msg, showRegularMessages: !msg.showRegularMessages } : msg
        )
      );
    });

    /**
     * Handles the 'end-meeting' event, which is triggered when the meeting ends.
     */
    socket.on('end-meeting', () => {
      console.log("Meeting ended");
      setRoom('');
      setUserType('');
      setUsers({});
      setMessages([]);
      setShowRegularMessages(false);
      setMcQuestion('');
      setMcOptions(['', '']);
      setTotalResponses(0);
      setMcResponses(new Map());
    });

    /**
     * Handles the 'force-reload' event, which forces the client to reload the page.
     */
    socket.on('force-reload', () => {
      console.log("Force reload");
      window.location.replace(process.env.REACT_APP_SOCKET_URL);
    });

    /**
     * Handles the 'mcResponse' event, which is triggered when a user submits a selection to a multiple choice question.
     * 
     * @param {string} id - The unique ID of the multiple choice question.
     * @param {number} index - The index of the selected option.
     * @param {boolean} increment - Whether to increment or decrement the response count.
     */
    socket.on('mcResponse', (id, index, increment) => {
      let count = 1;
      if(!increment){
        count = -1;
      }
      
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === id ? { ...msg, totalResponses: (msg.totalResponses || 0) + count } : msg
        )
      );
      handleOptionClick(id, index, count);
    });

    /**
     * Handles the 'calcMcResults' event, which triggers the calculation of multiple choice results.
     * 
     * @param {string} id - The unique ID of the multiple choice question.
     * @param {Array} options - The list of multiple choice options.
     */
    socket.on('calcMcResults', (id, options) => {
      broadcastMcQuestionResultsWrapper(options, id);
    });

    /**
     * Handles the 'regular-message' event, which is triggered when a regular message is sent by a moderator.
     * 
     * @param {Object} data - The data containing the regular message details.
     * @param {string} data.userType - The type of user who sent the message.
     * @param {string} data.message - The content of the message.
     */
    socket.on('regular-message', ({ userType: senderType, message }) => {
      if (userType === 'moderator') {
        setMessages((prevMessages) => [...prevMessages, { userType: senderType, message }]);
      }
    });

    /**
     * Handles the 'toggle-regular-messages' event, which toggles the visibility of messages for regular(participant) users.
     * 
     * @param {boolean} showRegularMessages - Whether to show regular messages.
     */
    socket.on('toggle-regular-messages', (showRegularMessages) => {
      setShowRegularMessages(showRegularMessages);
    });

    /**
     * Handles the 'response-updated' event, which updates the responses for a specific message.
     * 
     * @param {Object} data - The data containing the updated responses.
     * @param {string} data.id - The unique ID of the message.
     * @param {Array} data.newResponses - The updated list of responses.
     */
    socket.on('response-updated', ({ id, newResponses }) => {
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === id ? { ...msg, responses: newResponses } : msg
        )
      );
    });

    /**
     * Handles the 'show-response' event, which toggles the visibility of a specific response to a free response question.
     * 
     * @param {Object} data - The data containing the response visibility details.
     * @param {string} data.id - The unique ID of the message.
     * @param {number|null} data.responseIndex - The index of the response to toggle, or null to show all.
     */
    socket.on('show-response', ({ id, responseIndex }) => {
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === id ? {
            ...msg,
            responses: msg.responses.map((res, idx) =>
              responseIndex === null ? { ...res, show: true } : (idx === responseIndex ? { ...res, show: !res.show } : res)
            )
          } : msg
        )
      );
    });

    /**
     * Handles the 'messages-updated' event, which updates the list of messages.
     * 
     * @param {Array} newMessages - The updated list of messages.
     */
    socket.on('messages-updated', (newMessages) => {
      setMessages(newMessages);
    });

    /**
     * Handles the 'ratingQuestion' event, which is triggered when a new rating scale question is sent.
     * 
     * @param {Object} data - The data containing the rating scale question details.
     * @param {string} data.question - The rating scale question.
     * @param {string} data.id - The unique ID of the rating scale question.
     */
    socket.on('ratingQuestion', ({ question, id }) => {
      setMessages((prevMessages) => [
        ...prevMessages,
        { userType: "moderator", message: null, showRegularMessages: true, messageType: 2, id, question, responses: [] }
      ]);
    });

    /**
     * Handles the 'analyze-clicked' event, which is triggered when the AI analysis is clicked for a specific message.
     * 
     * @param {string} analyzeMessageId - The unique ID of the message to analyze.
     */
    socket.on('analyze-clicked', (analyzeMessageId) => {
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === analyzeMessageId ? { ...msg, analyzeClicked: true } : msg
        )
      );
    });

    /**
     * Handles the 'endRating' event, which is triggered when a rating scale question ends.
     * 
     * @param {string} id - The unique ID of the rating scale question.
     */
    socket.on('endRating', (id) => {
      setMessages((prevMessages) => prevMessages.map((msg) => {
        if (msg.messageType === 2 && msg.id === id) {
          return { ...msg, ended: true };
        }
        return msg;
      }));
    });

    /**
     * Handles the 'ratingResponse' event, which is triggered when a user submits a response to a rating scale question.
     * 
     * @param {Object} data - The data containing the rating response details.
     * @param {number} data.rating - The rating response value.
     * @param {string} data.id - The unique ID of the rating scale question.
     */
    socket.on('ratingResponse', ({ rating, id }) => {
      setMessages((prevMessages) => prevMessages.map((msg) => {
        if (msg.messageType === 2 && msg.id === id) {
          if (!msg.responses) {
            msg.responses = [];
          }
          msg.responses.push(rating);
          return msg;
        }
        return msg;
      }));
    });

    /**
     * Handles the 'disconnect' event, which is triggered when the client is disconnected from the server.
     */
    socket.on('disconnect', () => {
      console.error('Disconnected from the server');
      setDisconected(true);
      setShowConfirmExit(true);
    });

    // Cleanup event listeners on component unmount
    return () => {
      socket.off('user-joined');
      socket.off('user-left');
      socket.off('message');
      socket.off('msgError');
      socket.off('show-msg');
      socket.off('mcQuestion');
      socket.off('endMc');
      socket.off('end-meeting');
      socket.off('force-reload');
      socket.off('mcResponse');
      socket.off('calcMcResults');
      socket.off('regular-message');
      socket.off('toggle-regular-messages');
      socket.off('response-updated');
      socket.off('show-response');
      socket.off('messages-updated');
      socket.off('ratingQuestion');
      socket.off('endRating');
      socket.off('ratingResponse');
      socket.off('result');
      socket.off('showResult');
      socket.off('analyze-clicked');
      socket.off('McResult');
      socket.off('disconnect');
    };
  }, [
    userType,
    setUsers,
    setMessages,
    setMcQuestion,
    setMcOptions,
    initializeMcResponses,
    setTotalResponses,
    handleOptionClick,
    setShowRegularMessages,
    setRoom,
    setUserType,
    setMcResponses,
    handleMcItemEndedState,
  ]);
};
