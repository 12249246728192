import React from 'react';

/**
 * Represents the user list in the chat application.
 * Displays the number of moderators and participants in the room.
 * 
 * @class UserList
 * @classdesc UserList component renders the list of users in a chat room,
 * categorizing them into moderators and participants.
 * 
 * @param {Object} props - The component props.
 * @param {Object} props.users - The object containing the details of all users.
 * @returns {JSX.Element} The rendered user list component.
 */
const UserList = ({ users }) => {
  const moderatorUsers = Object.values(users).filter(user => user.userType === 'moderator');
  const participantUsers = Object.values(users).filter(user => user.userType !== 'moderator');

  return (
    <>
      <h3 className="text-center has-shadow">Users in Room <span>({Object.keys(users).length})</span></h3>
      <div className="panel-body">
        <p className="user-list-heading"><span>Moderators</span> <span className="badge">{moderatorUsers.length}</span></p>
        <div style={{ height: '20px' }}></div> {/* Spacer */}
        <p className="user-list-heading"><span>Participants</span> <span className="badge">{participantUsers.length}</span></p>
      </div>
    </>
  );
};

export default UserList;
