import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

/**
 * Represents the logout button in the application.
 * Triggers the Auth0 logout flow when clicked.
 * 
 * @class LogoutButton
 * @classdesc LogoutButton component initiates the authentication logout process
 * through Auth0 when the user clicks the button.
 * 
 * @returns {JSX.Element} The rendered button that initiates the Auth0 logout process.
 */
const LogoutButton = () => {
  const { logout } = useAuth0();
  
  return (
    <button className="btn btn-sm btn-secondary btn-block" onClick={() => logout({ returnTo: window.location.origin })}>
      Log Out
    </button>
  );
};

export default LogoutButton;
