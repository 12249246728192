import React, { createContext } from 'react';
import useChat from '../hooks/useChat';

/**
 * ChatContext provides a context for managing chat-related state and functions
 * throughout the application, including handling messages, user data, and room information.
 */
export const ChatContext = createContext();

/**
 * ChatProvider component wraps its children with the ChatContext provider,
 * making the chat-related state and functions available throughout the component tree.
 * 
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components that need access to the chat context.
 * @returns {JSX.Element} The ChatContext provider with the chat-related state and functions.
 */
export const ChatProvider = ({ children }) => {
  const chat = useChat();

  return (
    <ChatContext.Provider value={chat}>
      {children}
    </ChatContext.Provider>
  );
};
