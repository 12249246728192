/**
 * Manages operations related to rooms, including joining, creating, and processing files
 * associated with rooms within the application.
 * @module roomHandlers
 */

import { socket } from './socketEvents';

/**
 * Joins a room as a specified user type.
 * @function
 * @param {string} room - The name of the room to join.
 * @param {string} userTypeInput - The type of user (participant/moderator).
 * @param {function} setRoom - Function to set the current room name.
 * @param {function} setUserType - Function to set the user type.
 * @param {File} selectedFile - The file containing pre-set questions, if applicable.
 * @param {function} processFile - Function to process the pre-set questions file.
 * @param {string} roomCode - The code of the room to join.
 * @param {function} setRoomCode - Function to set the room code.
 * @param {Object} jsonPromptsFile - JSON file containing prompts for AI interactions.
 * @param {function} setChatGPTPrompts - Function to set the AI prompts.
 */
export const joinRoom = (room, userTypeInput, setRoom, setUserType, selectedFile, 
  processFile, roomCode, setRoomCode, jsonPromptsFile, setChatGPTPrompts) => {
  if (!room || !userTypeInput) {
    console.error('Missing parameters');
    return;
  }

  console.log('Joining room:', room, 'with code ', roomCode, 'as', userTypeInput);

  setRoom(room);
  setUserType(userTypeInput);
  setRoomCode(roomCode);
  setChatGPTPrompts(jsonPromptsFile);
  
  if (selectedFile && userTypeInput === 'moderator') {
    processFile(selectedFile);
  }
};

/**
 * Creates a room and joins it as a moderator.
 * @function
 * @param {string} room - The name of the room to create.
 * @param {function} setRoom - Function to set the current room name.
 * @param {function} setUserType - Function to set the user type.
 * @param {File} selectedFile - The file containing pre-set questions, if applicable.
 * @param {function} processFile - Function to process the pre-set questions file.
 * @param {string} roomCode - The code of the room to create.
 * @param {function} setRoomCode - Function to set the room code.
 * @param {Object} jsonPromptsFile - JSON file containing prompts for AI interactions.
 * @param {function} setChatGPTPrompts - Function to set the AI prompts.
 */
export const createRoom = (room, setRoom, setUserType, selectedFile, processFile, roomCode, setRoomCode, setChatGPTPrompts, jsonPromptsFile) => {
  console.log(`Room Created, joining ${room}`);
  
  setRoom(room);
  setUserType('moderator');
  setRoomCode(roomCode);
  console.log(`All prompts: ${jsonPromptsFile}`);
  setChatGPTPrompts(jsonPromptsFile);

  if (selectedFile) {
    processFile(selectedFile);
  }
};

/**
 * Processes the pre-set questions file (CSV or JSON format).
 * @function
 * @param {File} selectedFile - The file containing pre-set questions.
 * @param {function} setPreMcQuestions - Function to set multiple choice questions.
 * @param {function} setPreFreeQuestions - Function to set free response questions.
 * @param {function} setPreRatingQuestions - Function to set rating scale questions.
 */
export const processFile = (selectedFile, setPreMcQuestions, setPreFreeQuestions, setPreRatingQuestions) => {
  try {
    console.log("Processing file");
    
    const data = JSON.parse(selectedFile);

    console.log(`data in fileContent ${data}`);

    setPreMcQuestions(data.multiple_choice_questions || []);
    setPreFreeQuestions(data.free_response_questions || []);
    setPreRatingQuestions(data.rating_scale_questions || []);
  } catch (error) {
    console.error('Error processing file:', error);
  }
};

/**
 * Handles the join room form submission. Gathers the information sent by the user and verifies it.
 * @function
 * @param {Event} e - The form submission event.
 * @param {function} setRoomNameError - Function to set an error message for room name.
 * @param {function} setPasswordError - Function to set an error message for password.
 */
export const handleJoin = (e, setRoomNameError, setPasswordError) => {
  e.preventDefault();
  
  const roomCode = document.querySelector("#room").value;
  const userTypeInput = document.querySelector("#userType").value;
  const password = document.querySelector("#moderatorPassword")?.value;
  let selectedFile = null;

  if (userTypeInput === 'moderator') {
    selectedFile = document.getElementById("file").files[0];
  }

  if (!roomCode) {
    setRoomNameError('Room code is required.');
    console.error('Room code is required.');
  } else {
    setRoomNameError('');
  }

  if (userTypeInput === 'moderator' && !password) {
    setPasswordError('Password required for moderators');
    console.error('Password required for moderators');
  } else {
    setPasswordError('');
  }

  if (!roomCode || (userTypeInput === 'moderator' && !password)) {
    return;
  }

  console.log(`Verifying user for roomCode ${roomCode}`);

  if (selectedFile) {
    const reader = new FileReader();
    reader.onload = function (event) {
      const content = event.target.result;
      const isCSV = selectedFile.type === 'text/csv';
      const jsonContent = isCSV ? csvToJson(content) : content;
      socket.emit('verify', { roomCode, password, userType: userTypeInput, selectedFile: jsonContent });
    };
    reader.readAsText(selectedFile);
  } else {
    socket.emit("verify", { roomCode, password, userType: userTypeInput, selectedFile });
  }
};

/**
 * Handles the create room form submission. Gathers the information sent by the user 
 * and emits it to the server to handle.
 * @function
 * @param {Event} e - The form submission event.
 * @param {string} currentSubType - The subscription type of the user.
 * @param {function} setRoomNameError - Function to set an error message for room name.
 * @param {function} setPasswordError - Function to set an error message for password.
 * @param {string} userId - The ID of the user creating the room.
 */
export const handleCreateRoom = (e, currentSubType, setRoomNameError, setPasswordError, userId) => {
  e.preventDefault();
  
  const room = document.querySelector("#room").value;
  const password = document.querySelector("#moderatorPassword").value;
  const subType = currentSubType;
  let selectedFile = document.getElementById("file").files[0];

  console.log(`Selected file in handleCreateRoom: ${selectedFile}`);

  if (!room) {
    setRoomNameError('Room name is required.');
    console.error('Room name is required.');
  } else {
    setRoomNameError('');
  }

  if (!password) {
    setPasswordError('Moderator password is required.');
    console.error('Moderator password is required.');
  } else {
    setPasswordError('');
  }

  if (!room || !password) {
    return;
  }

  console.log('Creating room:', room, password);

  // Generate a 6-digit room code
  const roomCode = Math.floor(100000 + Math.random() * 900000).toString();
  console.log('Generated Room Code:', roomCode);

  const emitCreateRoom = (jsonContent) => {
    socket.emit('createRoom', { room, password, selectedFile: jsonContent, subType, roomCode, userId });
  };

  if (selectedFile) {
    const reader = new FileReader();
    reader.onload = function (event) {
      const content = event.target.result;
      const isCSV = selectedFile.type === 'text/csv';
      const jsonContent = isCSV ? csvToJson(content) : content;
      emitCreateRoom(jsonContent);
    };
    reader.readAsText(selectedFile);
  } else {
    emitCreateRoom(null);
  }
};

/**
 * Converts CSV content to JSON format.
 * @function
 * @param {string} csv - The CSV content to convert.
 * @returns {string} The converted JSON content.
 */
function csvToJson(csv) {
  const lines = csv.split('\n');
  const result = {
    multiple_choice_questions: [],
    free_response_questions: [],
    rating_scale_questions: []
  };

  for (let i = 1; i < lines.length; i++) {
    const line = lines[i].trim();
    if (line) {
      const [type, question, options] = line.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);

      if (type === "Multiple Choice") {
        result.multiple_choice_questions.push({
          question: question,
          options: options ? options.split(',').map(option => option.trim().replace(/^"|"$/g, '')) : []
        });
      } else if (type === "Free Response") {
        result.free_response_questions.push({ question: question });
      } else if (type === "Rating Scale") {
        result.rating_scale_questions.push({ question: question });
      }
    }
  }

  return JSON.stringify(result, null, 2);
}
