import React, { useState, useContext, useEffect, useRef } from 'react';
import { ChatContext } from '../context/ChatContext';
import { socket } from '../hooks/socketEvents';
import '../styles/style.css';
import { format } from 'date-fns';

/**
 * Represents a rating scale question item in the chat application.
 * Renders the rating scale, allows users to submit their ratings,
 * and displays the results if the question has ended.
 * 
 * @class RatingScaleItem
 * @classdesc RatingScaleItem component manages the display and interaction
 * for rating scale questions within the chat.
 * 
 * @param {Object} props - The component props.
 * @param {Object} props.msg - The message object containing the question and responses.
 * @param {boolean} props.ended - Whether the question has ended.
 * @param {string} props.id - The unique ID of the message.
 * @param {Object} props.scale - The scale object containing the min and max values for the rating.
 * @param {string} props.timestamp - The timestamp of when the message was sent.
 * @returns {JSX.Element} The rendered rating scale item component.
 */
const RatingScaleItem = ({ msg, ended, id, scale, timestamp }) => {
  const { userType, handleEndRatingQuestion, handleShowResult } = useContext(ChatContext);
  const [rating, setRating] = useState(scale ? scale.min : 1);
  const [sent, setSent] = useState(false);
  const [scrambledMessage, setScrambledMessage] = useState("");
  const [question] = useState(msg.message);
  const rangeRef = useRef(null);

  const formattedTime = format(new Date(timestamp), 'p');

  /**
   * Handles changes in the rating input field.
   * 
   * @method
   * @param {Event} e - The input change event.
   */
  const handleRatingChange = (e) => {
    setRating(e.target.value);
  };

  /**
   * Updates the visual representation of the rating scale as the rating changes.
   * 
   * @method
   */
  useEffect(() => {
    if (rangeRef.current) {
      const percentage = (rating - rangeRef.current.min) / (rangeRef.current.max - rangeRef.current.min) * 100;
      rangeRef.current.style.setProperty('--value', `${percentage}%`);
    }
  }, [rating]);

  /**
   * Updates the scrambled message used to obfuscate user responses.
   * 
   * @method
   */
  useEffect(() => {
    const responses = msg.responses || [];
    const responseCounts = responses.reduce((acc, response) => {
      acc[response] = (acc[response] || 0) + 1;
      return acc;
    }, {});

    setScrambledMessage(Object.entries(responseCounts)
      .map(([value, count]) => (
        randomizeString(count.toString().length + value.toString().length + 10)
      )));
  }, [msg.responses]);

  /**
   * Sends the selected rating to the server.
   * 
   * @method
   */
  const sendRating = () => {
    if (!sent) {
      socket.emit('ratingResponse', { rating: parseInt(rating), id });
      setSent(true);
    }
  };

  /**
   * Automatically sends the rating if the question has ended and the user is not a moderator. 
   * Moderators must send manually.
   * 
   * @method
   */
  useEffect(() => {
    if (ended && !sent && userType !== 'moderator') {
      sendRating();
    }
  }, [ended]);

  /**
   * Generates a random string of a specified length.
   * 
   * @method
   * @param {number} length - The length of the random string.
   * @returns {string} The generated random string.
   */
  const randomizeString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  /**
   * Renders the average rating and response counts, concealing the data if the user is not allowed to see it.
   * 
   * @method
   * @returns {JSX.Element} The rendered average rating and response counts.
   */
  const renderAverageRating = () => {
    const responses = msg.responses || [];
    const responseCount = responses.length;

    if (responseCount === 0) {
      return 'No responses';
    }

    const averageRating = (responses.reduce((sum, response) => sum + response, 0) / responseCount).toFixed(2);

    const responseCounts = responses.reduce((acc, response) => {
      acc[response] = (acc[response] || 0) + 1;
      return acc;
    }, {});

    const responseCountsElements = Object.entries(responseCounts)
      .map(([value, count]) => (
        <div key={value}>{count} {count === 1 ? 'user answered' : 'users answered'}: {value}</div>
      ));

    return (
      <>
        <span>
          {msg.showRegularMessages || userType === 'moderator'
            ? `${averageRating} (${responseCount} responses)`
            : '?? (?? responses)'}
        </span>
        <div className={msg.showRegularMessages || userType === 'moderator' ? '' : 'hidden-item'}>
          {msg.showRegularMessages || userType === 'moderator' ? responseCountsElements : scrambledMessage}
        </div>
      </>
    );
  };

  return (
    <div className="chat-item moderator">
      <div className="chat-heading">
        <p className="user-type">Moderator <span className="time">{formattedTime}</span></p>
        <p className="message">{question ? question : 'No question provided'}</p>
      </div>
      <div className="chat-body">
        {!ended ? (
          <form className="slider-question">
            <div className="row mb-4">
              <div className="col-lg-6 d-flex align-items-center">
                <div className="range">
                  <input
                    type="range"
                    min={scale ? scale.min : 1}
                    max={scale ? scale.max : 5}
                    value={rating}
                    onChange={handleRatingChange}
                    disabled={sent}
                    className="range-input"
                    ref={rangeRef}
                  />
                  <div className="range-value">{rating}</div>
                </div>
              </div>
              <div className="col-lg-3 d-flex align-items-center">
              </div>
            </div>
          </form>
        ) : (
          <div className="answer-item">
            <p className={msg.showRegularMessages || userType === 'moderator' ? '' : 'hidden-item'}>
              Average rating: {renderAverageRating()}
            </p>
            {userType === 'moderator' && (
              <div className="button-group">
                <button className="btn btn-sm btn-secondary" onClick={() => handleShowResult(id)}>
                  {msg.showRegularMessages ? "Hide" : "Show"}
                </button>
              </div>
            )}
          </div>
        )}
        <div>
          {userType === 'moderator' && !ended && (
            <button className="btn btn-danger" onClick={() => handleEndRatingQuestion(id)}>
              End question
            </button>
          )}
           {!sent && userType === 'moderator' && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={sendRating}
              disabled={sent}
            >
              Send
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RatingScaleItem;
