/**
 * Handles various message-related operations, including sending, updating, 
 * and toggling the visibility of messages within the application.
 * @module messageHandlers
 */

import { socket } from './socketEvents';

/**
 * Handles the event of sending a message.
 * @function
 * @param {Event} e - The form submission event.
 * @param {string} message - The message to be sent.
 * @param {string} userType - The type of user sending the message.
 * @param {int} messageType - The type of message/question (e.g., text, multiple choice, etc.).
 * @param {function} setMessage - Function to reset the message input.
 * @param {Object} scale - The scale object used for rating questions, if applicable.
 */
export const handleSendMessage = (e, message, userType, messageType, setMessage, scale) => {
  e.preventDefault();

  if (message.trim() === '') {
    return;
  }

  const timestamp = new Date().toISOString();
  const payload = { userType, message, messageType, scale, timestamp };
  socket.emit('message', payload);
  setMessage('');
};

/**
 * Handles the change event for message input.
 * @function
 * @param {Event} e - The input change event.
 * @param {function} setMessage - Function to set the message.
 */
export const handleMessageChange = (e, setMessage) => {
  setMessage(e.target.value);
};

/**
 * Toggles the visibility of regular messages.
 * @function
 * @param {Object} socket - The socket object.
 * @param {string} room - The current room.
 */
export const handleToggleRegularMessages = (socket, room) => {
  socket.emit('toggle-regular-messages', { room });
};

/**
 * Handles the click event for showing or hiding a message.
 * @function
 * @param {number} index - The index of the message to show/hide.
 * @param {Array} displayedMessages - The list of displayed messages.
 * @param {function} setMessages - Function to update the list of messages.
 */
export const handleShowClick = (index, displayedMessages, setMessages) => {
  const message = displayedMessages[index];
  const show = !message.showRegularMessages;

  socket.emit('show-msg', message.id);

  setMessages((prevMessages) =>
    prevMessages.map((msg, i) =>
      i === index ? { ...msg, showRegularMessages: show } : msg
    )
  );
};

/**
 * Handles the event of showing results for a question (e.g., rating scale, multiple choice, etc.).
 * @function
 * @param {string} id - The ID of the message to show results for.
 */
export const handleShowResult = (id) => {
  socket.emit("showResult", id);
};
