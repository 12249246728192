import React, { useContext, useEffect, useRef, useState } from 'react';
import { Modal } from 'bootstrap';
import { ChatContext } from '../context/ChatContext';
import aiIcon from '../img/24x24-AI-Icon-White.svg';
import { marked } from 'marked';
import { socket } from '../hooks/socketEvents';

/**
 * Represents the AI analysis prompt modal in the chat application.
 * Allows users to generate, select, and submit AI-based analysis prompts
 * from default or suggested options.
 * 
 * @class PromptModal
 * @classdesc PromptModal component manages the display and interaction
 * for AI-generated analysis prompts, including submitting the chosen prompt.
 * 
 * @returns {JSX.Element|null} The rendered prompt modal component, or null if not shown.
 */
const PromptModal = () => {
  const {
    chatGPTMessages, showPromptModal, setShowPromptModal, prompt, setPrompt, handlePromptChange, handleSubmitPrompt, 
    handleChatGPT, userType, fetchBestPrompts, guideQuestions, setGuideQuestions, messages, setMessages, analyzeMessageId, 
    setAnalyzeMessageId
  } = useContext(ChatContext) || {};

  const defaultPrompts = [
    { label: "Summarize", prompt: "Summarize the key points from the selected messages." },
    { label: "Example", prompt: "Give an example of what is described in the selected messages." },
    { label: "Pros/Cons", prompt: "What are some potential pros and cons of what is described in the selected messages?" },
    { label: "Follow-up", prompt: "Based on the given responses, what are 3 potential follow-up questions?" },
    { label: "Preferences", prompt: "Identify the preferences or opinions expressed in the selected messages." },
    { label: "Sentiment", prompt: "Analyze the sentiment of the selected messages to determine the overall attitude or tone." },
    { label: "Solution", prompt: "Based on the given responses, suggest an optimal solution or compromise." },
    { label: "Compare", prompt: "Compare and contrast the selected messages to highlight similarities and differences." },
    { label: "Themes", prompt: "Identify any common themes or recurring ideas in the selected messages." },
    { label: "Differences", prompt: "What are the key differences between the opinions or points made in the selected messages?" }
  ];

  const modalRef = useRef(null);
  const [promptsFetched, setPromptsFetched] = useState(null);
  const [isInvalid, setIsInvalid] = useState(false);

  /**
   * Fetches the best AI prompts for the current context when the modal is shown.
   * 
   * @method
   */
  useEffect(() => {
    if (showPromptModal && chatGPTMessages && !promptsFetched) {
      fetchBestPrompts(chatGPTMessages, setGuideQuestions).then(() => {
        setPromptsFetched(true);
      });
    }
  }, [showPromptModal]);

  /**
   * Initializes and displays the modal when `showPromptModal` is true.
   * Cleans up the modal and removes it from the DOM when hidden.
   * 
   * @method
   */
  useEffect(() => {
    let myModal = null;
    if (showPromptModal) {
      const modalElement = document.getElementById('AnalyzeAiModal');
      myModal = new Modal(modalElement);
      myModal.show();

      modalElement.addEventListener('hidden.bs.modal', () => {
        setShowPromptModal(false);
        document.body.classList.remove('modal-open');
        document.querySelector('.modal-backdrop')?.remove();
      });
    }
    return () => {
      if (myModal) {
        myModal.hide();
      }
      setPromptsFetched(false);
      setGuideQuestions(null);
      setPrompt(null);
    };
  }, [showPromptModal]);

  /**
   * Handles cancellation of the AI analysis prompt submission.
   * 
   * @method
   */
  const handleCancel = () => {
    const updatedMessages = messages.map(msg => 
      msg.id === analyzeMessageId ? { ...msg, analyzeClicked: false } : msg
    );
    setMessages(updatedMessages);
    setShowPromptModal(false);
    setAnalyzeMessageId(null);
    setPromptsFetched(false);
    setGuideQuestions(null);
    setPrompt(null);
  };

  /**
   * Submits the selected AI analysis prompt for processing.
   * 
   * @method
   */
  const handleSubmit = () => {
    if (!prompt || prompt.trim() === '') {
      setIsInvalid(true);
      return;
    }

    const messageToAnalyze = messages.find(msg => msg.id === analyzeMessageId);
    
    handleSubmitPrompt(setShowPromptModal, handleChatGPT, prompt, userType, messageToAnalyze);

    const updatedMessages = messages.map(msg =>
      msg.id === analyzeMessageId ? { ...msg, analyzeClicked: true } : msg
    );

    socket.emit('analyze-clicked', analyzeMessageId);
    setMessages(updatedMessages);
  };

  /**
   * Formats messages into a Markdown format suitable for display.
   * 
   * @method
   * @param {string} messages - The messages to format.
   * @returns {string} The formatted messages as HTML.
   */
  const formatMessages = (messages) => {
    const questionMatch = messages.match(/\*\*\*Question\*\*\*\n(.+?)\n\*\*Responses\*\*/);
    const question = questionMatch ? questionMatch[1] : '';
    const responsesMatch = messages.match(/\*\*Responses\*\*\n((?:Response \d+: .+\n?)+)/);
    const responses = responsesMatch ? responsesMatch[1] : '';
    let formattedMessages = `### Question: ${question}\n---\n`;

    if (responses) {
      const responseRegex = /Response (\d+): (.+)/g;
      let responseMatch;
      while ((responseMatch = responseRegex.exec(responses)) !== null) {
        formattedMessages += `**Response ${responseMatch[1]}:** ${responseMatch[2]}\n\n`;
      }
    } else {
      formattedMessages += 'No responses available.\n';
    }

    return marked(formattedMessages.trim(), { mangle: false });
  };

  if (!showPromptModal) return null;

  return (
    <div className="modal fade" tabIndex="-1" id="AnalyzeAiModal">
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content" ref={modalRef}>
          <div className="modal-body">
            <div className="modal-inner">
              <div className="panel-icon mb-4">
                <img src={aiIcon} alt="Modal icon" />
              </div>
              <h3 className="text-center mb-5">AI Analyze</h3>
              <form>
                <div className="mb-3">
                  <label className="form-label">Analyze Messages</label>
                  <textarea
                    className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
                    placeholder="Enter your prompt here..."
                    name="message"
                    value={prompt}
                    onChange={(e) => {
                      handlePromptChange(e, setPrompt);
                      setIsInvalid(false);
                    }}
                  />
                  {isInvalid && <div className="invalid-feedback">This field is required.</div>}
                </div>
                <div className="mb-3">
                  <label className="form-label">AI Generated Prompts</label>
                  <div className="row justify-content-md-center">
                    {guideQuestions && guideQuestions.length > 0 ? (
                      guideQuestions.map((guide, index) => (
                        <div className="d-grid mb-2" key={index}>
                          <button
                            type="button"
                            className="btn btn-outline-secondary btn-block text-center"
                            onClick={() => handlePromptChange({ target: { value: guide.prompt } }, setPrompt)}
                          >
                            {guide.label}
                          </button>
                        </div>
                      ))
                    ) : (
                      "Loading..."
                    )}
                  </div>
                </div>
                <div className="mb3">
                  <label className="form-label">Default Prompts</label>
                  <div className="row justify-content-md-center">
                    {defaultPrompts.map((guide, index) => (
                      <div className="col-lg-3 col-md-6" key={index}>
                        <div className="d-grid mb-2">
                          <button
                            type="button"
                            className="btn btn-outline-secondary btn-block text-center"
                            onClick={() => handlePromptChange({ target: { value: guide.prompt } }, setPrompt)}
                          >
                            {guide.label}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Message to Send</label>
                  <div
                    className="panel-text text-mono text-white"
                    dangerouslySetInnerHTML={{ __html: formatMessages(chatGPTMessages) }}
                  />
                </div>
                <div className="row mt-5">
                  <div className="col-6">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={handleCancel}
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-6 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromptModal;
