import React, { useContext, useState, useEffect } from 'react';
import { ChatContext } from '../context/ChatContext';
import '../styles/style.css';
import { Tooltip } from 'bootstrap';

/**
 * Represents the message form in the chat application.
 * Handles the input and submission of messages,
 * including different question types and rating scales for moderators.
 * 
 * @class MessageForm
 * @classdesc MessageForm component manages the input and submission of chat messages,
 * providing options for moderators to select question types and rating scales.
 * 
 * @param {Object} props - The component props.
 * @param {string} props.message - The current message input value.
 * @param {Function} props.handleSendMessage - Function to handle sending the message.
 * @param {Function} props.handleMessage - Function to handle changes in the message input.
 * @param {Object} props.chatBodyRef - Ref to the chat body element for managing layout.
 * @param {Function} props.adjustMainContentMargin - Function to adjust the margin of the main content.
 * @returns {JSX.Element} The rendered message form component.
 */
const MessageForm = ({ message, handleSendMessage, handleMessage, chatBodyRef, adjustMainContentMargin }) => {
  const {
    messageType, preFreeQuestions, preRatingQuestions,
    showPreFreeQuestions, userType, setShowPreFreeQuestions,
    showPreRatingQuestions, setShowPreRatingQuestions,
    setMessageType, messageError
  } = useContext(ChatContext);

  const [showQuestionIcons, setShowQuestionIcons] = useState(false);
  const [customScale, setCustomScale] = useState({ min: 1, max: 1 });
  const [selectedScale, setSelectedScale] = useState({ min: 1, max: 5 });
  const [isMinimized, setIsMinimized] = useState(false);

  /**
   * Initializes Bootstrap tooltips when the component mounts.
   * 
   * @method
   */
  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = Array.from(tooltipTriggerList).map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));

    return () => {
      tooltipList.forEach((tooltip) => tooltip.dispose());
    };
  }, []);

  /**
   * Resets the selected scale when the message type changes.
   * 
   * @method
   */
  useEffect(() => {
    if (messageType !== 2) {
      setSelectedScale({ min: 1, max: 5 });
    }
  }, [messageType]);

  /**
   * Handles the display of error messages when message errors occur.
   * 
   * @method
   */
  useEffect(() => {
    if (messageError) {
      const textarea = document.getElementById('messageTextarea');
      textarea.classList.add('is-invalid');
    }
  }, [messageError]);

  /**
   * Toggles the class of the plus icon based on the visibility of the question icons.
   * 
   * @method
   */
  useEffect(() => {
    const plusIcon = document.getElementById('icon-question-toggle');
    if (plusIcon) {
      if (showQuestionIcons) {
        plusIcon.classList.add('close');
      } else {
        plusIcon.classList.remove('close');
      }
    }
  }, [showQuestionIcons]);

  /**
   * Handles the keydown event for sending messages when Enter is pressed.
   * 
   * @method
   * @param {Event} event - The keydown event.
   */
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSendMessage(event, selectedScale);
    }
  };

  /**
   * Handles the selection of predefined questions.
   * 
   * @method
   * @param {string} question - The selected question.
   */
  const handleQuestionClick = (question) => {
    handleMessage({ target: { value: question } });
    setShowPreFreeQuestions(false);
    setShowPreRatingQuestions(false);
    setShowQuestionIcons(false); 
  };

  /**
   * Toggles the selection of question types (e.g., multiple choice, free response, slider).
   * 
   * @method
   * @param {number} buttonIndex - The index of the selected question type.
   */
  const handleButtonClick = (buttonIndex) => {
    if (messageType === buttonIndex) {
      setMessageType(null);
    } else {
      setMessageType(buttonIndex);
    }
  
    const textarea = document.getElementById('messageTextarea');
    if (textarea.classList.contains('is-invalid')) {
      textarea.classList.remove('is-invalid');
    }
  
    setShowQuestionIcons(false);
  };

  /**
   * Toggles the visibility of the question type icons.
   * 
   * @method
   */
  const toggleQuestionIcons = () => {
    setShowQuestionIcons((prev) => !prev);
  };

  /**
   * Handles clicks on the pseudo-element for moderators. The pseudo-element is the question type icons as seen on mobile.
   * 
   * @method
   * @param {Event} event - The click event.
   */
  const handlePseudoElementClick = (event) => {
    if (messageType === null || userType !== 'moderator') {
      return;
    }
    const questionInput = event.currentTarget;
    const rect = questionInput.getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const clickY = event.clientY - rect.top;

    const pseudoElementWidth = 40;
    const pseudoElementHeight = 40;
    const pseudoElementLeft = 10;
    const pseudoElementTop = (rect.height / 2) - (pseudoElementHeight / 2);

    if (clickX >= pseudoElementLeft && clickX <= (pseudoElementLeft + pseudoElementWidth) && clickY >= pseudoElementTop && clickY <= (pseudoElementTop + pseudoElementHeight)) {
      setShowQuestionIcons((prev) => !prev);
    }
  };

  /**
   * Handles the selection of a rating scale.
   * 
   * @method
   * @param {number} min - The minimum value of the scale.
   * @param {number} max - The maximum value of the scale.
   */
  const handleScaleSelection = (min, max) => {
    setSelectedScale({ min, max });
  };

  /**
   * Handles changes in the custom scale input fields.
   * 
   * @method
   * @param {Event} e - The input change event.
   */
  const handleCustomScaleChange = (e) => {
    const { name, value } = e.target;
    setCustomScale((prev) => ({ ...prev, [name]: value }));
    setSelectedScale((prev) => ({ ...prev, [name]: value }));
  };

  /**
   * Adjusts the main content margin whenever the message or message type changes.
   * 
   * @method
   */
  useEffect(() => {
    adjustMainContentMargin();
  }, [message, messageType, chatBodyRef.current?.offsetHeight]);

  /**
   * Wrapper function for handling the send message event.
   * 
   * @method
   * @param {Event} e - The form submission event.
   */
  const handleSendMessageWrapper = (e) => {
    e.preventDefault();
    const textarea = document.getElementById('messageTextarea');
  
    if (!message.trim()) {
      textarea.classList.add('is-invalid');
    } else {
      textarea.classList.remove('is-invalid');
      handleSendMessage(e, selectedScale);
    }
  };

  /**
   * Toggles the minimized state of the question panel.
   * 
   * @method
   */
  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
    setShowPreFreeQuestions((prev) => !prev);
    setShowPreRatingQuestions((prev) => !prev);
  };

  const isModerator = userType === 'moderator';

  return (
    <div
      className={`chat-input ${isModerator ? 'chat-input-moderator' : ''}
      ${messageType === 0 ? 'multiple-choice-active' : ''}
      ${messageType === 1 ? 'free-response-active' : ''}
      ${messageType === 2 ? 'slider-active' : ''} fixed`}
      ref={chatBodyRef}
    >
      {messageType === 1 && preFreeQuestions.length > 0 && (
        <div className={`panel card question-panel free-response-panel ${isMinimized ? 'minimize' : ''}`}>
          <div className="card-header d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={toggleMinimize}>
            <strong>Free Response</strong>
            <small>Select one question below:</small>
            <div className="icon-group">
              <span className="icon icon-minimize" id="question-minimize"></span>
            </div>
          </div>
          {showPreFreeQuestions && (
            <div className="card-body">
              <ul className="list-group">
                {preFreeQuestions.map((questionObj, index) => (
                  <li className="list-group-item" key={index} onClick={() => handleQuestionClick(questionObj.question)}>
                    <div className="d-grid">
                      <input
                        className="btn-check"
                        type="radio"
                        name="free-response-question"
                        id={`Q${index}`}
                        autoComplete="off"
                      />
                      <label className="btn btn-outline-secondary btn-block" htmlFor={`Q${index}`}>
                        {questionObj.question}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}

      {messageType === 2 && preRatingQuestions.length > 0 && (
        <div className={`panel card question-panel slider-panel ${isMinimized ? 'minimize' : ''}`}>
          <div className="card-header d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={toggleMinimize}>
            <strong>Rating Questions</strong>
            <small>Select one question below:</small>
            <div className="icon-group">
              <span className="icon icon-minimize" id="question-minimize"></span>
            </div>
          </div>
          {showPreRatingQuestions && (
            <div className="card-body">
              <ul className="list-group">
                {preRatingQuestions.map((questionObj, index) => (
                  <li className="list-group-item" key={index} onClick={() => handleQuestionClick(questionObj.question)}>
                    <div className="d-grid">
                      <input
                        className="btn-check"
                        type="radio"
                        name="rating-question"
                        id={`R${index}`}
                        autoComplete="off"
                      />
                      <label className="btn btn-outline-secondary btn-block" htmlFor={`R${index}`}>
                        {questionObj.question}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}

      <div className="chat-input-body">
        <form>
          <div className="main-input">
            <div className="question-input" onClick={handlePseudoElementClick}>
              <div>
                <button onClick={handleSendMessageWrapper} className="btn btn-primary">Send</button>
                {(userType === 'moderator' && !messageError) && (
                  <div className="plus-icon-questions" id="icon-question-toggle" onClick={toggleQuestionIcons}></div>
                )}
                <textarea
                  id="messageTextarea"
                  className="form-control"
                  placeholder="Type your message…"
                  value={message}
                  onChange={(e) => {
                    handleMessage(e);
                    if (e.target.classList.contains('is-invalid')) {
                      e.target.classList.remove('is-invalid');
                    }
                  }}
                  onKeyDown={handleKeyDown}
                />
              </div>
              {messageError && <div className="invalid-feedback">{messageError}</div>}
            </div>
            {(isModerator && !messageError) && (
              <div className={`questions-icons ${showQuestionIcons ? 'show-up' : ''}`}>
                <a
                  className={`icon icon-multiple-choice ${messageType === 0 ? 'active' : ''}`}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-title="Multiple Choice"
                  onClick={() => handleButtonClick(0)}
                  onKeyDown={handleKeyDown}
                ></a>
                <a
                  className={`icon icon-free-response ${messageType === 1 ? 'active' : ''}`}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-title="Free Response"
                  onClick={() => handleButtonClick(1)}
                  onKeyDown={handleKeyDown}
                ></a>
                <a
                  className={`icon icon-slider ${messageType === 2 ? 'active' : ''}`}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-title="Slider"
                  onClick={() => handleButtonClick(2)}
                  onKeyDown={handleKeyDown}
                ></a>
              </div>
            )}
          </div>

          {messageType === 2 && (
            <div className="input-answer row mt-4">
              <div className="col-xxl-3 col-lg-6">
                <div className="d-grid mb-2">
                  <input className="btn-check" type="radio" name="rating-scale" id="Scale1to10" autoComplete="off" onClick={() => handleScaleSelection(1, 10)} />
                  <label className="btn btn-outline-secondary btn-block" htmlFor="Scale1to10">
                    Scale 1 to 10
                  </label>
                </div>
              </div>
              <div className="col-xxl-3 col-lg-6">
                <div className="d-grid mb-2">
                  <input className="btn-check" type="radio" name="rating-scale" checked={selectedScale.min === 1 && selectedScale.max === 5} id="Scale1to5" autoComplete="off" onClick={() => handleScaleSelection(1, 5)} />
                  <label className="btn btn-outline-secondary btn-block" htmlFor="Scale1to5">
                    Scale 1 to 5
                  </label>
                </div>
              </div>
              <div className="col-xxl-3 col-lg-6">
                <div className="d-grid mb-2">
                  <input className="btn-check" type="radio" name="rating-scale" id="Scale1to3" autoComplete="off" onClick={() => handleScaleSelection(1, 3)} />
                  <label className="btn btn-outline-secondary btn-block" htmlFor="Scale1to3">
                    Scale 1 to 3
                  </label>
                </div>
              </div>
              <div className="col-xxl-3 col-lg-6">
                <div className="d-grid mb-2">
                  <input className="btn-check" type="radio" name="rating-scale" id="CustomScale" autoComplete="off" onClick={() => handleScaleSelection(customScale.min, customScale.max)} />
                  <label className="btn btn-outline-secondary btn-block" htmlFor="CustomScale">
                    Custom
                    <input type="text" name="min" value={customScale.min} className="val-range" onChange={handleCustomScaleChange} />
                    to
                    <input type="text" name="max" value={customScale.max} className="val-range" onChange={handleCustomScaleChange} />
                  </label>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default MessageForm;
