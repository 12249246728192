import React, { useEffect, useContext, useState } from 'react';
import { Modal } from 'bootstrap';
import { ChatContext } from '../context/ChatContext';

import endMeetingIcon from "../img/96x96-End-Meeting-Icon.svg";
import downloadSummaryIcon from "../img/96x96-Download-Summary-Icon.svg";
import styles from '../styles/ExitModal.module.css';
import logoImage from '../img/MainLogo.png';

import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { saveAs } from 'file-saver';
import { marked } from 'marked';
import { htmlToText } from 'html-to-text';
import TurndownService from 'turndown';

/**
 * Represents the exit modal in the chat application.
 * Handles the confirmation process for ending a meeting,
 * including options to download the meeting notes with or without an AI summary.
 * 
 * @class ExitModal
 * @classdesc ExitModal component handles the display and functionality of the exit confirmation modal.
 */
const ExitModal = () => {
  const {
    showConfirmExit, setShowConfirmExit, handleEndMeeting, displayedMessages, room, summarizeMeeting, disconnected, userType, chatGPTPrompts
  } = useContext(ChatContext);
  const [view, setView] = useState('initial');
  const turndownService = new TurndownService();

  /**
   * Initializes and displays the modal when `showConfirmExit` is true.
   * Cleans up the modal and removes it from the DOM when hidden.
   * 
   * @method
   */
  useEffect(() => {
    let myModal = null;
    if (showConfirmExit) {
      const modalElement = document.getElementById('ModalEndMeeting');
      myModal = new Modal(modalElement, {
        backdrop: disconnected ? 'static' : true,
        keyboard: !disconnected
      });
      myModal.show();

      modalElement.addEventListener('hidden.bs.modal', () => {
        setShowConfirmExit(false);
        document.body.classList.remove('modal-open');
        document.querySelector('.modal-backdrop')?.remove();
      });
    }
    return () => {
      if (myModal) {
        myModal.hide();
      }
    };
  }, [showConfirmExit, disconnected, setShowConfirmExit]);

  if (!showConfirmExit) return null;

  /**
   * Renders AI-generated HTML content from a JSON response object.
   * 
   * @method
   * @param {Object|string} response - The JSON response object or string to render as HTML.
   * @returns {string} The rendered HTML content.
   */
  const renderAiHTML = (response) => {
    try {
        const parsedResponse = typeof response === 'string' ? JSON.parse(response) : response;
  
        if (parsedResponse && typeof parsedResponse === 'object') {
            return `
                <strong>Analysis Type:</strong> ${parsedResponse.analysisType || 'No analysis type'}<br>
                <strong>Follow-Up Questions:</strong>
                <ul style="margin-bottom:0;">
                  ${Array.isArray(parsedResponse.followUpQuestions)
                    ? parsedResponse.followUpQuestions.map(q => `<li>${q}</li>`).join('')
                    : 'No follow-up questions available'}
                </ul>
                <br>
                <strong>Findings:</strong>
                ${renderFindings(parsedResponse.findings)}
            `;
        } else {
            console.error('Parsed response is not a valid object:', parsedResponse);
            return response;
        }
    } catch (error) {
        console.error('Error parsing JSON:', error, 'Response:', response);
        return response;
    }
  };

  /**
   * Renders the findings section from an AI-generated response.
   * 
   * @method
   * @param {Array<Object>} findings - The findings array containing sections and items.
   * @returns {string} The rendered findings HTML content.
   */
  const renderFindings = (findings) => {
    return findings.map((section) => `
        <br><strong>${section.heading}:</strong><br>
        <ul>
          ${section.items.map(item => `<li><strong>${item.title}:</strong> ${item.description}</li>`).join('')}
        </ul>
    `).join('');
  };

  /**
   * Handles the download of the meeting text as a PDF document, with an optional AI summary.
   * 
   * @method
   * @param {boolean} includeSummary - Whether to include an AI summary in the download.
   */
  const handleDownloadMeetingText = async (includeSummary) => {
    setView("loading");

    const renderAverageRating = (msg) => {
      const responses = msg.responses || [];
      const responseCount = responses.length;

      if (responseCount === 0) {
        return 'No responses';
      }

      const averageRating = (responses.reduce((sum, response) => sum + response, 0) / responseCount).toFixed(2);

      const responseCounts = responses.reduce((acc, response) => {
        acc[response] = (acc[response] || 0) + 1;
        return acc;
      }, {});

      const responseCountsString = Object.entries(responseCounts)
        .map(([value, count]) => `${count} user(s) answered: ${value}`)
        .join(', ');

      return `Average: ${averageRating} (${responseCount} responses)\n${responseCountsString}`;
    };

    const convertMarkdownToPlainText = (markdownText) => {
      const cleanedMarkdown = markdownText
        .replace(/\n\s*\n/g, '\n')  // Keep necessary spacing
        .trim();  // Remove leading/trailing whitespace

      const htmlText = marked(cleanedMarkdown);
      return htmlToText(htmlText, {
        wordwrap: false,  // Prevents adding unnecessary line breaks
        singleNewLineParagraphs: true,  // Use a single newline for paragraphs
        preserveNewlines: true,  // Preserve newlines where needed
      }).trim();  // Remove extra leading/trailing whitespace
    };

    const convertAiMarkdownToPlainText = (markdownText) => {
      let cleanedMarkdown = markdownText
        .replace(/\*\*Analysis Type:\*\*/g, '*Analysis Type:')  // Add asterisk before "Analysis Type:"
        .replace(/\*\*Follow-Up Questions:\*\*/g, '\n*Follow-Up Questions:')  // Add asterisk before "Follow-Up Questions:" with a newline before
        .replace(/\*\*Findings:\*\*/g, '\n\n*Findings:')  // Add asterisk before "Findings:" with double newline before
        .replace(/\*\*(.+?):\*\*/g, '*$1:')  // Add asterisk before sub-headers under "Findings"
        .replace(/^\s*\*\s(?=\*\*)/gm, ' ')  // Prevent double asterisks on bullet points with subheader format
        .replace(/^\s*\*\s/gm, '  * ')  // Indent bullet points under sub-headers by two spaces
        .replace(/\n\s*\n/g, '\n\n')  // Replace single newlines with double newlines
        .replace(/\n{2,}/g, '\n\n')  // Ensure double newlines between sections
        .replace(/\*\s*\*/g, '*')
        .replace(/\*\s{3}/g, '*')
        .trim();  // Clean up any leading/trailing whitespace

      // Add two spaces before every line
      cleanedMarkdown = cleanedMarkdown.split('\n').map(line => '  ' + line).join('\n');

      return cleanedMarkdown;
    };

    const convertJsonAiObject = (jsonObject) => {
      const aiHTML = renderAiHTML(jsonObject);
      const aiMarkdown = turndownService.turndown(aiHTML);
      return convertAiMarkdownToPlainText(aiMarkdown);
    };

    const formatMessage = (msg) => {
      let formattedMessage = ``;

      switch (msg.messageType) {
        case 0:
          formattedMessage += `Multiple Choice Question: ${msg.message}\n`;
          msg.mcResponses.forEach((response) => {
            const clicks = response.clicks || 0;
            const percentage = msg.totalResponses > 0 ? (clicks / msg.totalResponses) * 100 : 0;
            formattedMessage += `${response.option}: ${percentage.toFixed(2)}% (${clicks})\n`;
          });
          break;
        case 1:
          formattedMessage += `Free Response Question: ${msg.message}`;
          if (msg.responses && Array.isArray(msg.responses)) {
            const responsesText = msg.responses.map(response => {
              let responseText = response.text;
              if (response.isAI) {
                responseText = convertJsonAiObject(responseText);
                responseText = responseText.replace(/^(\s*)\*/gm, '$1• ');
                return `AI Response:\n${responseText}`;
              }
              return `Response: ${responseText}`;
            }).join('\n');
            formattedMessage += `\nAll Responses:\n${responsesText}`;
          }
          break;
        case 2:
          formattedMessage += `Slider Question: ${msg.message}`;
          formattedMessage += `\n${renderAverageRating(msg)}`;
          break;
        default:
          formattedMessage += `Message: ${msg.message}`;
          break;
      }

      return formattedMessage;
    };

    let meetingText = displayedMessages.map(formatMessage).join('\n\n' + '-'.repeat(112) + '\n\n');

    if (includeSummary) {
      let chatGPTSummary = await summarizeMeeting(displayedMessages, chatGPTPrompts);
      chatGPTSummary = convertMarkdownToPlainText(chatGPTSummary);
      chatGPTSummary = chatGPTSummary.replace(/^(\s*)\*/gm, '$1• ');
      meetingText = meetingText + '\n\n' + '-'.repeat(112) + '\n\n' + chatGPTSummary;
    }

    if (!meetingText) {
      alert('No text available to download');
      setView("initial");
      return;
    }

    const pdfDoc = await PDFDocument.create();
    const regularFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
    const boldFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);
    let page = pdfDoc.addPage();
    const { width, height } = page.getSize();
    const fontSize = 12;
    const margin = 50;
    const regularMaxWidth = width - margin * 2;
    const aiMaxWidth = regularMaxWidth - 175;

    const img = new Image();
    img.src = logoImage;
    await new Promise(resolve => {
      img.onload = resolve;
    });

    const logoBytes = await fetch(logoImage).then(res => res.arrayBuffer());
    const logoImageEmbed = await pdfDoc.embedPng(logoBytes);

    const originalWidth = img.width;
    const originalHeight = img.height;

    const desiredWidth = 150;
    const aspectRatio = originalHeight / originalWidth;
    const desiredHeight = desiredWidth * aspectRatio;

    page.drawImage(logoImageEmbed, {
      x: margin,
      y: height - margin - desiredHeight,
      width: desiredWidth,
      height: desiredHeight,
    });

    page.drawText('Meeting Summary', {
      x: margin,
      y: height - margin - desiredHeight - 20,
      size: fontSize + 4,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    page.drawText('Generated by Timely Insights', {
      x: margin,
      y: height - margin - desiredHeight - 40,
      size: fontSize,
      font: regularFont,
      color: rgb(0, 0, 0),
    });

    let yPosition = height - margin - desiredHeight - 60;

    const splitTextIntoLines = (text, maxWidth, fontSize, font) => {
      const words = text.split(' ');
      let lines = [];
      let currentLine = words[0];

      for (let i = 1; i < words.length; i++) {
        const word = words[i];
        const lineWidth = font.widthOfTextAtSize(currentLine + " " + word, fontSize);
        if (lineWidth < maxWidth) {
          currentLine += " " + word;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      }
      lines.push(currentLine);
      return lines;
    };

    const lines = meetingText.split('\n');
    for (const line of lines) {
      let processedLine = line;
      let boldUntilColon = '';
      let maxWidth = regularMaxWidth;

      // Check if line starts with a bullet point, remove it, and find text until the colon
      if (/^\s*•/.test(line)) {
        const match = processedLine.match(/^(.*?):/);
        if (match) {
          boldUntilColon = match[1] + ':'; // Capture text until colon
          processedLine = processedLine.replace(boldUntilColon, '').trim(); // Remove bolded part from line and trim
        }
      }

      // Adjust maxWidth if this is an AI response
      if (line.includes('•')) {
        maxWidth = aiMaxWidth;
      }

      const wrappedLines = splitTextIntoLines(processedLine, maxWidth, fontSize, regularFont);

      let isHeaderAdded = false;

      for (const wrappedLine of wrappedLines) {
        if (yPosition <= 40) {
          yPosition = height - margin;
          page = pdfDoc.addPage();
        }

        if (boldUntilColon && !isHeaderAdded) {
          const boldWidth = boldFont.widthOfTextAtSize(boldUntilColon, fontSize);

          // Draw bold text
          page.drawText(boldUntilColon, {
            x: margin,
            y: yPosition,
            size: fontSize,
            font: boldFont,
            color: rgb(0, 0, 0),
          });

          // Draw the remaining text after the bold part
          page.drawText(wrappedLine, {
            x: margin + boldWidth + 5,
            y: yPosition,
            size: fontSize,
            font: regularFont,
            color: rgb(0, 0, 0),
          });

          isHeaderAdded = true;
        } else {
          page.drawText(wrappedLine, {
            x: margin,
            y: yPosition,
            size: fontSize,
            font: regularFont,
            color: rgb(0, 0, 0),
          });
        }

        yPosition -= fontSize + 2;
      }
    }

    const pdfBytes = await pdfDoc.save();
    const date = new Date().toISOString().split('T')[0];
    const filename = `TimelyInsightsMeetingNotes_${room}_${date}.pdf`;
    saveAs(new Blob([pdfBytes]), filename);

    setView("initial");
  };

  return (
    <div className="modal fade" tabIndex="-1" id="ModalEndMeeting">
      <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
        <div className="modal-content">
          <div className="modal-body">
            {view === 'initial' ? (
              <div className="modal-inner">
                <div className="empty-state text-center mb-5">
                  <img src={endMeetingIcon} alt="Empty state image"/>
                  <h2>{disconnected ? 'You have been disconnected' : 'Are you sure you want to end the meeting?'}</h2>
                  <p>
                    {disconnected ? (
                      <>
                        You may rejoin if the room is still active.<br />
                        {userType === 'moderator' && (
                          <>If you wish to download these messages, you must do so now.<br /></>
                        )}
                      </>
                    ) : (
                      'This will close the meeting for all users currently in the room.'
                    )}
                  </p>
                </div>
                {userType === 'moderator' && (
                  <div className="d-grid mb-4">
                    <button className="btn btn-primary btn-block" onClick={() => setView('confirmSummary')}>Download meeting text</button>
                  </div>
                )}
                {disconnected && (
                  <div className="d-grid mb-4">
                    <button className="btn btn-danger btn-block" onClick={() => window.location.reload()}>Reload</button>
                  </div>
                )}
                {!disconnected && (
                  <div className="row">
                    <div className="col-6 d-grid">
                      <button className="btn btn-danger" onClick={() => { handleEndMeeting(); setShowConfirmExit(false); }}>End meeting</button>
                    </div>
                    <div className="col-6 d-grid">
                      <button className="btn btn-secondary" onClick={() => setShowConfirmExit(false)}>Cancel</button>
                    </div>
                  </div>
                )}
              </div>
            ) : view === 'confirmSummary' ? (
              <div className="modal-inner">
                <div className="empty-state text-center mb-5">
                  <img src={downloadSummaryIcon} alt="Empty state image"/>
                  <h2>Would you like a summary of meeting included?</h2>
                  <p>Select yes to include an AI summary, select no to just download all the messages</p>
                </div>
                <div className="row">
                  <div className="col-md-4 d-grid">
                    <button className="btn btn-primary my-2" onClick={() => handleDownloadMeetingText(true)}>Yes</button>
                  </div>
                  <div className="col-md-4 d-grid">
                    <button className="btn btn-danger my-2" onClick={() => handleDownloadMeetingText(false)}>No</button>
                  </div>
                  <div className="col-md-4 d-grid">
                    <button className="btn btn-secondary my-2" onClick={() => setView('initial')}>Cancel</button>
                  </div>
                </div>
              </div>
            ) : view === 'loading' ? (
              <div className={styles.spinner}></div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExitModal;
