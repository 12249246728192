import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';
import { ChatContext } from '../context/ChatContext';
import '../styles/style.css';

/**
 * Represents an individual user response in the chat.
 * Allows moderators to toggle the visibility of the response and view AI-generated content.
 * 
 * @class UserResponseItem
 * @classdesc UserResponseItem component renders a user response, with options for moderators to manage visibility
 * and interact with AI-generated responses.
 * 
 * @param {Object} props - The component props.
 * @param {string} props.response - The text of the user's response.
 * @param {Function} props.onShowClick - Function to toggle the visibility of the response.
 * @param {boolean} props.isModerator - Indicates if the current user is a moderator.
 * @param {boolean} props.show - Indicates if the response is currently visible.
 * @param {boolean} props.isAI - Indicates if the response is generated by AI.
 * @returns {JSX.Element} The rendered user response item component.
 */
const UserResponseItem = ({ response, onShowClick, isModerator, show, isAI }) => {
  const { setMessage, setMessageType } = useContext(ChatContext);
  const [scrambledMessage, setScrambledMessage] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const moreIconRef = useRef(null);

  /**
   * Toggles the visibility of the dropdown menu for response actions.
   * 
   * @method
   */
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  /**
   * Sets the reference to the dropdown element and adds/removes event listeners
   * for detecting clicks outside the dropdown.
   * 
   * @method
   * @param {HTMLElement|null} node - The dropdown element or null.
   */
  const setDropdownRef = (node) => {
    if (node) {
      dropdownRef.current = node;
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  };

  /**
   * Handles window resize events to hide the dropdown if the window width exceeds a threshold.
   * 
   * @method
   */
  const handleResize = useCallback(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1025) {
      setDropdownVisible(false);
    }
  }, []);

  /**
   * Handles scroll events to hide the dropdown.
   * 
   * @method
   */
  const handleScroll = useCallback(() => {
    setDropdownVisible(false);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleResize, handleScroll]);

  /**
   * Hides the dropdown menu if a click is detected outside of it.
   * 
   * @method
   * @param {Event} event - The click event.
   */
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  /**
   * Scrambles the message to hide its content unless it is visible.
   * 
   * @method
   */
  const scrambleMessage = () => {
    const originalMessage = "You're gonna have to be smarter than that";
    if (response && response.length > originalMessage.length) {
      setScrambledMessage(originalMessage + randomizeString(response.length - originalMessage.length));
    } else {
      setScrambledMessage(originalMessage);
    }
  };

  /**
   * Generates a random string of a specified length.
   * 
   * @method
   * @param {number} length - The length of the random string.
   * @returns {string} The generated random string.
   */
  const randomizeString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = ' ';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  /**
   * Renders a follow-up question for the AI messages and allows the moderator to click on it to set the message.
   * 
   * @method
   * @param {Object} props - The component props.
   * @param {string} props.question - The follow-up question text.
   * @param {Function} props.onClick - Function to handle the click event.
   * @returns {JSX.Element} The rendered follow-up question component.
   */
  const FollowUpQuestion = ({ question, onClick }) => {
    const handleClick = () => {
      onClick(question);
      setMessageType(1); 
    };
  
    return (
      <span 
        style={{ cursor: 'pointer' }} 
        className='aiQuestions-item'
        onClick={handleClick}
      >
        {question}
      </span>
    );
  };

  /**
   * Renders the findings from the AI analysis.
   * 
   * @method
   * @param {Array} findings - The array of findings to render.
   * @returns {JSX.Element} The rendered findings.
   */
  const renderFindings = (findings) => {
    return findings.map((section, index) => (
      <div key={index}>
        <strong>{section.heading}:</strong>
        <ul>
          {section.items.map((item, idx) => (
            <li key={idx}>
              <strong>{item.title}:</strong> {item.description}
            </li>
          ))}
        </ul>
      </div>
    ));
  };

  /**
   * Renders an AI response into HTML, parsing it into structured content if it is a valid JSON string.
   * 
   * @method
   * @returns {JSX.Element} The rendered markdown content.
   */
  const renderMarkdown = () => {
    try {
      const parsedResponse = typeof response === 'string' ? JSON.parse(response) : response;
  
      if (parsedResponse && typeof parsedResponse === 'object') {
        return (
          <>
            {isModerator && (
              <>
                <strong>Analysis Type:</strong>
                <p>{parsedResponse.analysisType || 'No analysis type'}</p>
                <div className="aiQuestions">
                  <strong>Follow-Up Questions:</strong>
                  <ul style={{marginBottom:0}}>
                    {Array.isArray(parsedResponse.followUpQuestions)
                      ? parsedResponse.followUpQuestions.map((q, index) => (
                          <li key={index}>
                            <FollowUpQuestion question={q} onClick={setMessage} />
                          </li>
                        ))
                      : 'No follow-up questions available'}
                  </ul>                
                </div>
              </>
            )}
            <strong>Findings:</strong>
            {renderFindings(parsedResponse.findings)}
          </>
        );
      } else {
        console.error('Parsed response is not a valid object:', parsedResponse);
        return <>{response}</>;
      }
    } catch (error) {
      console.error('Error parsing JSON:', error, 'Response:', response);
      return <>{response}</>;
    }
  };

  useEffect(() => {
    scrambleMessage();
  }, [response]);

  return (
    <div className={`answer-item mb-2 ${isAI ? 'ai-response auto-content' : ''}`}>
      {isAI ? (
        <>
          <div className="response-heading">
            <strong>AI Analysis</strong>
            <div className="button-group">
              {isModerator && (
                <button className="btn btn-sm btn-secondary" onClick={onShowClick}>
                  {show ? "Hide" : "Show"}
                </button>
              )}
            </div>
          </div>
          <div className="response-body">
            <p className={!show && !isModerator ? "hidden-item message" : ""}>
              {!show && !isModerator 
                ? scrambledMessage 
                : renderMarkdown()}
            </p>
          </div>
        </>
      ) : (
        <>
          <p className={!show && !isModerator ? "hidden-item message" : "message"}>
            {!show && !isModerator ? scrambledMessage : response}
          </p>
          {isModerator && (
            <div className={`dropdown dropdown-actions ${dropdownVisible ? 'show' : ''}`} ref={setDropdownRef}>
              <div
                className={`more-icon ${dropdownVisible ? 'show' : ''}`}
                onClick={toggleDropdown}
                ref={moreIconRef}
              ></div>
              <ul className={`dropdown-menu ${dropdownVisible ? 'show' : ''}`} style={{ paddingRight: '5px' }}>
                <button className={`${dropdownVisible ? 'dropdown-item' : 'btn btn-sm btn-secondary'}`} onClick={onShowClick}>
                  {show ? "Hide" : "Show"}
                </button>
              </ul>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UserResponseItem;
