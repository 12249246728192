import React, { useState, useContext, useEffect } from 'react';
import { ChatContext } from '../context/ChatContext';
import { Tooltip } from 'bootstrap';

/**
 * Represents the multiple-choice message form in the chat application.
 * Handles the input and submission of multiple-choice messages,
 * including options management and validation for moderators.
 * 
 * @class McMessageForm
 * @classdesc McMessageForm component allows moderators to create and submit multiple-choice questions
 * with options, managing the state and validation of the form inputs.
 * 
 * @param {Object} props - The component props.
 * @param {Function} props.adjustMainContentMargin - Function to adjust the margin of the main content.
 * @returns {JSX.Element} The rendered multiple-choice message form component.
 */
const McMessageForm = ({ adjustMainContentMargin }) => {
  const {
    handleMultipleChoice, preMcQuestions, showPreMcQuestions, setShowPreMcQuestions,
    setMessageType, messageType, userType, message, setMessage
  } = useContext(ChatContext);

  const [localMcOptions, setLocalMcOptions] = useState(['', '']);
  const [invalidOptions, setInvalidOptions] = useState([false, false]);
  const [showQuestionIcons, setShowQuestionIcons] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);

  /**
   * Initializes Bootstrap tooltips when the component mounts.
   * Disposes of tooltips on component unmount.
   * 
   * @method
   */
  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
      if (Tooltip.getInstance(tooltipTriggerEl)) {
        Tooltip.getInstance(tooltipTriggerEl).dispose();
      }
      new Tooltip(tooltipTriggerEl);
    });

    return () => {
      tooltipTriggerList.forEach((tooltipTriggerEl) => {
        if (Tooltip.getInstance(tooltipTriggerEl)) {
          Tooltip.getInstance(tooltipTriggerEl).dispose();
        }
      });
    };
  }, [localMcOptions, invalidOptions]);

  /**
   * Adjusts the main content margin whenever the options or questions are updated.
   * 
   * @method
   */
  useEffect(() => {
    adjustMainContentMargin();
  }, [localMcOptions.length, showPreMcQuestions, message]);

  /**
   * Handles the keydown event for submitting the form when Enter is pressed.
   * 
   * @method
   * @param {Event} event - The keydown event.
   */
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleFormSubmit();
    }
  };

  /**
   * Toggles the selection of message types (e.g., multiple choice, free response).
   * 
   * @method
   * @param {number} buttonIndex - The index of the selected message type.
   */
  const handleButtonClick = (buttonIndex) => {
    if (messageType === buttonIndex) {
      setMessageType(null);
    } else {
      setMessageType(buttonIndex);
    }
  
    const textarea = document.getElementById('localMcQuestionTextarea');
    if (textarea.classList.contains('is-invalid')) {
      textarea.classList.remove('is-invalid');
    }
  
    setShowQuestionIcons(false); 
  };
  
  /**
   * Validates and submits the multiple-choice question and options.
   * 
   * @method
   */
  const handleFormSubmit = () => {
    const newInvalidOptions = localMcOptions.map(option => option.trim() === '');
    setInvalidOptions(newInvalidOptions);
  
    const textarea = document.getElementById('localMcQuestionTextarea'); 
  
    if (message.trim() === '') {
      textarea.classList.add('is-invalid');
    } else {
      textarea.classList.remove('is-invalid');
    }
  
    if (message.trim() !== '' && newInvalidOptions.every(invalid => !invalid)) {
      handleMultipleChoice(message, localMcOptions);
      setMessage('');
      setLocalMcOptions(['', '']);
      setInvalidOptions([false, false]);
    }
  };
  
  /**
   * Handles changes in the multiple-choice question input field.
   * 
   * @method
   * @param {Event} e - The input change event.
   */
  const handleLocalMcQuestionChange = (e) => {
    setMessage(e.target.value);
  };

  /**
   * Handles changes in the multiple-choice option input fields.
   * 
   * @method
   * @param {number} index - The index of the option being changed.
   * @param {string} value - The new value of the option.
   */
  const handleLocalMcOptionChange = (index, value) => {
    const newOptions = [...localMcOptions];
    newOptions[index] = value;
    setLocalMcOptions(newOptions);

    if (invalidOptions[index]) {
      const newInvalidOptions = [...invalidOptions];
      newInvalidOptions[index] = false;
      setInvalidOptions(newInvalidOptions);
    }
  };

  /**
   * Adds a new option to the multiple-choice form.
   * 
   * @method
   */
  const handleAddLocalOption = () => {
    if (localMcOptions.length < 5) {
      setLocalMcOptions([...localMcOptions, '']);
      setInvalidOptions([...invalidOptions, false]);
    }
  };

  /**
   * Removes an option from the multiple-choice form.
   * 
   * @method
   * @param {number} index - The index of the option to remove.
   */
  const handleRemoveLocalOption = (index) => {
    if (localMcOptions.length > 2) {
      setLocalMcOptions(localMcOptions.filter((_, i) => i !== index));
      setInvalidOptions(invalidOptions.filter((_, i) => i !== index));
    }
  };

  /**
   * Handles the selection of predefined multiple-choice questions.
   * 
   * @method
   * @param {string} question - The selected question.
   * @param {Array<string>} options - The options associated with the selected question.
   */
  const handleQuestionClick = (question, options) => {
    setMessage(question);
    setLocalMcOptions(options.slice(0, 4));
    setShowPreMcQuestions(false);
  };

  /**
   * Toggles the visibility of the question type icons.
   * 
   * @method
   */
  const toggleQuestionIcons = () => {
    setShowQuestionIcons((prev) => !prev);
  };

  /**
   * Toggles the minimized state of the question panel.
   * 
   * @method
   */
  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
    setShowPreMcQuestions(prev => !prev);
  };

  /**
   * Handles clicks on the pseudo-element for moderators. The pseudo-element is the question type icons as seen on mobile.
   * 
   * @method
   * @param {Event} event - The click event.
   */
  const handlePseudoElementClick = (event) => {
    if(messageType === null || userType !== 'moderator'){
      return;
    }
    const questionInput = event.currentTarget;
    const rect = questionInput.getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const clickY = event.clientY - rect.top;
  
    const pseudoElementWidth = 40; 
    const pseudoElementHeight = 40;
    const pseudoElementLeft = 10;
    const pseudoElementTop = (rect.height / 2) - (pseudoElementHeight / 2);
  
    if (clickX >= pseudoElementLeft && clickX <= (pseudoElementLeft + pseudoElementWidth) && clickY >= pseudoElementTop && clickY <= (pseudoElementTop + pseudoElementHeight)) {
      setShowQuestionIcons((prev) => !prev);
    }
  };

  /**
   * Toggles the class of the plus icon based on the visibility of the question icons.
   * 
   * @method
   */
  useEffect(() => {
    const plusIcon = document.getElementById('icon-question-toggle');
    if (showQuestionIcons) {
      plusIcon.classList.add('close');
    } else {
      plusIcon.classList.remove('close');
    }
  }, [showQuestionIcons]);

  return (
    <div className={`chat-input ${userType === 'moderator' ? 'chat-input-moderator' : ''} multiple-choice-active fixed`}>
    
      {preMcQuestions.length > 0 && (
        <div className={`panel card question-panel multiple-choice-panel ${isMinimized ? 'minimize' : ''}`}>
          <div className="card-header d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={toggleMinimize}>
            <strong>Multiple Choice</strong>
            <small>Select one question below:</small>
            <div className="icon-group">
              <span className="icon icon-minimize" id="question-minimize"></span>
            </div>
          </div>
          {showPreMcQuestions && (
            <div className="card-body">
              <ul className="list-group">
                {preMcQuestions.map((questionObj, index) => (
                  <li className="list-group-item" key={index} onClick={() => handleQuestionClick(questionObj.question, questionObj.options)}>
                    <div className="d-grid">
                      <input
                        className="btn-check"
                        type="radio"
                        name="multiple-choice-question"
                        id={`Q${index}`}
                        autoComplete="off"
                      />
                      <label className="btn btn-outline-secondary btn-block" htmlFor={`Q${index}`}>
                        {questionObj.question}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}

      <div className="chat-input-body">
        <form onSubmit={(e) => { e.preventDefault(); handleFormSubmit(); }}>
          <div className="main-input">
            <div className="question-input" onClick={handlePseudoElementClick}>
              <button type="submit" className="btn btn-primary">Send</button>
              {userType==='moderator' && (
                 <div className="plus-icon-questions" id="icon-question-toggle" onClick={toggleQuestionIcons}></div>
              )}
              <textarea 
                id="localMcQuestionTextarea"
                className="form-control" 
                placeholder="Type your message…"
                value={message}
                onChange={(e) => {
                  handleLocalMcQuestionChange(e);
                  const textarea = e.target;
                  if (textarea.classList.contains('is-invalid')) {
                    textarea.classList.remove('is-invalid');
                  }
                }}
                onKeyDown={handleKeyDown}>
              </textarea>
            </div>

            {userType === 'moderator' && (
              <div className={`questions-icons ${showQuestionIcons ? 'show-up' : ''}`}>
                <a 
                  className={`icon icon-multiple-choice ${messageType === 0 ? 'active' : ''}`} 
                  data-bs-toggle="tooltip" 
                  data-bs-placement="top"
                  data-bs-title="Multiple Choice" 
                  onClick={() => handleButtonClick(0)} 
                  onKeyDown={handleKeyDown}
                ></a>
                <a 
                  className={`icon icon-free-response ${messageType === 1 ? 'active' : ''}`} 
                  data-bs-toggle="tooltip" 
                  data-bs-placement="top"
                  data-bs-title="Free Response" 
                  onClick={() => handleButtonClick(1)} 
                  onKeyDown={handleKeyDown}
                ></a>
                <a 
                  className={`icon icon-slider ${messageType === 2 ? 'active' : ''}`} 
                  data-bs-toggle="tooltip" 
                  data-bs-placement="top"
                  data-bs-title="Slider" 
                  onClick={() => handleButtonClick(2)} 
                  onKeyDown={handleKeyDown}
                ></a>
              </div>
            )}
          </div>

          <div className="input-answer row mt-4">
            {localMcOptions.slice(0, 4).map((option, index) => (
              <div className="answer-input-group col-md-6" key={index}>
                {localMcOptions.length > 2 && (
                  <span className="icon-action" onClick={() => handleRemoveLocalOption(index)}></span>
                )}
                <input
                  className={`form-control ${invalidOptions[index] ? 'is-invalid' : ''}`}
                  type="text"
                  value={option}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => handleLocalMcOptionChange(index, e.target.value)}
                  placeholder={`Option ${index + 1}`}
                  data-bs-toggle={invalidOptions[index] ? 'tooltip' : ''}
                  data-bs-title={invalidOptions[index] ? 'Required' : ''}
                />
              </div>
            ))}
          </div>
          {localMcOptions.length < 4 && (
            <button type="button" className="btn btn-secondary" onClick={handleAddLocalOption}>Add Option</button>
          )}
        </form>
      </div>
    </div>
  );
};

export default McMessageForm;
